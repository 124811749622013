import {siteConstants} from "../_constants";
import {sitesService} from "../_services";
import {history} from "../_helpers";
import {alertActions, loaderActions} from './'

function getList(searchVal) {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        sitesService.getAll().then(
            (response) => {
                let sites = response.data ? response.data.Items : [];
                if(searchVal) {
                    dispatch(getAllSuccess(filterResult(sites, searchVal)));
                } else {
                    dispatch(getAllSuccess(sites));
                }
                dispatch(loaderActions.clear());
            }, (error) => dispatchError(error, dispatch));
    }
}

function filterResult (sites, searchVal) {
    return sites.filter(item => {
        return item.Name?.toLowerCase().includes(searchVal.toLowerCase())
            || item.Description?.toLowerCase().includes(searchVal.toLowerCase())
    });
}

function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        sitesService.getSingle(id).then(
            (siteResp) => {
                dispatch(getSingleSuccess(siteResp));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            });
    }
}

function save(data, isUpdate) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        sitesService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/sites');
                const message = isUpdate ? 'Site updated successfully' : 'Site saved successfully';
                dispatch(alertActions.success(message));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function deleteSite(siteId) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        sitesService.deleteSite(siteId).then(
            (response) => {
                dispatch(alertActions.success('Site Deleted Successfully'));
                dispatch(sitesActions.getList());
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function getAllSuccess(sites) {
    return {type: siteConstants.GET_ALL_SUCCESS, sites};
}

function saveSuccess(site) {
    return {type: siteConstants.SAVE_SUCCESS, site};
}

function getSingleSuccess(site) {
    return {type: siteConstants.GET_SINGLE_SUCCESS, site};
}

function failure(error) {
    return {type: siteConstants.FAILURE, error};
}


export const sitesActions = {
    getList,
    save,
    getSingle,
    deleteSite
};
