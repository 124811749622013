import React from "react";
import {AppBar, IconButton, makeStyles, Toolbar, Typography,} from "@material-ui/core";
import {Person as PersonIcon,} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import {accountActions, surfaceActions} from "../../_actions";
import {history} from "../../_helpers";
import {isAdministrator} from "../../Common/helpers";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: "none",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
}));

function TopAppBar() {
    const surface = useSelector((state) => state.surface);
    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const open = Boolean(profileAnchorEl);
    const dispatch = useDispatch();

    const classes = useStyles();

    const handleProfileMenu = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(accountActions.logout());
        history.push("/login");
    }

    return (
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    className={classes.menuButton}
                    onClick={() => surface.leftDrawerRequired && dispatch(surfaceActions.toggleDrawer())}
                >
                    <MenuIcon/>
                </IconButton>
                <img src="https://tekwavebirdstorage.blob.core.windows.net/media/antevorta.png" width="300px" />
                <Typography className={classes.title} noWrap>
                    {surface.title}
                </Typography>
                <div className={classes.grow}/>

                <IconButton color="inherit" variant="contained" onClick={handleProfileMenu}>
                    <PersonIcon/>
                </IconButton>
                <Menu
                    anchorEl={profileAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleProfileClose}
                >
                    {
                     isAdministrator() && <MenuItem onClick={() => history.push('/admin')}>Administration</MenuItem>
                    }
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default TopAppBar;
