import React, {Fragment} from 'react';
import {default as AdminDashboard} from "./Dashobard/Dashboard";
import {default as AdminClientList} from "./Clients/ClientList";
import {default as AdminSiteView} from "./Sites/SiteList";
import {default as AddSiteView} from "./Sites/AddSite";
import {default as AdminRegionView} from "./Regions/RegionList";
import {default as AdminUserView} from "./Users/UserList";
import {default as AdminModelView} from "./Models/ModelsHomeView";
import {default as AddClientView} from "./Clients/AddClient";
import {default as AddUserView} from "./Users/AddUser";
import {default as AddRegionView} from "./Regions/AddRegion";
import {default as AdminDeviceList} from "./Devices/DeviceList";
import {default as AddDeviceList} from "./Devices/AddDevice";
import {Route} from "react-router-dom";


const FlowAdmin = () => {

    return (
      <Fragment>
          <Route path="/admin" exact component={AdminDashboard} />
          <Route path="/admin/clients" exact component={AdminClientList} />
          <Route  path={['/admin/client/new', '/admin/client/update/:clientId']} exact  component={AddClientView} />
          <Route path="/admin/sites" exact  component={AdminSiteView} />
          <Route path={["/admin/site/new", "/admin/site/update/:siteId"]} exact  component={AddSiteView} />
          <Route path="/admin/regions" exact  component={AdminRegionView} />
          <Route path={["/admin/region/new", "/admin/region/update/:regionId"]} exact  component={AddRegionView} />
          <Route path="/admin/users" exact  component={AdminUserView} />
          <Route path={["/admin/user/new", "/admin/user/update/:userId"]} exact  component={AddUserView} />
          <Route path="/admin/models" exact  component={AdminModelView} />
          <Route path={["/admin/device/new", "/admin/device/update/:deviceId"]} exact  component={AddDeviceList} />
          <Route path="/admin/devices" exact  component={AdminDeviceList} />
      </Fragment>
    );
}

export default FlowAdmin;