import { loaderConstants } from '../_constants';

export function loader(state = {isLoading: false}, action) {
  switch (action.type) {
    case loaderConstants.IS_LOADING:
      return {
        isLoading: true
      };
    case loaderConstants.CLEAR:
      return {
        isLoading: false
      };
    default:
      return state
  }
}