import React from "react";
import {AppBar, fade, IconButton, InputBase, makeStyles, Toolbar, Typography,} from "@material-ui/core";
import {Notifications as NotificationsIcon, Person as PersonIcon, Search as SearchIcon,} from "@material-ui/icons";
import AppsMenu from "./AppsMenu";
import {useDispatch, useSelector} from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {accountActions} from "../../_actions";
import {history} from "../../_helpers";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: "none",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

function TopAppBar() {
    const surface = useSelector((state) => state.surface);
    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const open = Boolean(profileAnchorEl);
    const dispatch = useDispatch();

    const classes = useStyles();

    const handleProfileMenu = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(accountActions.logout());
        history.push("/login");
    }

    const search = (event) => {
        if (event.key === 'Enter') {
            return surface.searchFunc ?
                surface.searchFunc(event.target.value) :
                () => {
                }
        }
    }

    return (
        <AppBar position="absolute" color="primary" className={classes.appBar}>
            <Toolbar>
                <img src="https://tekwavebirdstorage.blob.core.windows.net/media/antevorta.png" width="300px"/>
                <Typography className={classes.title} noWrap>
                    {surface.title}
                </Typography>
                <div className={classes.grow}/>
                {
                    surface.showHeaderSearchBar &&
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon/>
                        </div>
                        <InputBase
                            onKeyPress={search}
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{"aria-label": "search"}}
                        />
                    </div>

                }
                <AppsMenu/>
                <IconButton color="inherit" variant="contained">
                    <NotificationsIcon/>
                </IconButton>
                <IconButton color="inherit" variant="contained" onClick={handleProfileMenu}>
                    <PersonIcon/>
                </IconButton>
                <Menu
                    anchorEl={profileAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleProfileClose}
                >
                    <MenuItem onClick={() => history.push('/')}>Customer Dashboard</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default TopAppBar;
