import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    error: {
        color: '#FFF',
        background: '#f50057',
        padding: '4px',
        borderRadius: '20px',
    },
    errorContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

function SerialNumberView(device) {
    const classes = useStyles();

    return (
        <Fragment>
            {
                device && device.Error && device.Error.ErrorCode !== 'E00' ?
                    <Box className={classes.errorContainer}>
                        <Typography>{device.SerialNumber}</Typography>
                        <Typography className={classes.error} variant={"caption"}>MAINTENANCE</Typography>
                    </Box>
                    : <Typography>{device.SerialNumber}</Typography>
            }
        </Fragment>
    );
}

SerialNumberView.PropType = {
    device: PropTypes.object.isRequired
};

export default SerialNumberView;
