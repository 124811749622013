import React from "react";
import PropTypes from "prop-types";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    label: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold,
    },
    text: {
        fontSize: 12,
    }
}));

function LabeledText(props) {
    const {label, text} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.label} variant="caption" display="block" >
                {label}
            </Typography>
            <Typography className={classes.text} variant="caption" display="block" >
                {text}
            </Typography>
        </div>
    );
}

LabeledText.PropType = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export default LabeledText;
