import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, deviceActions, surfaceActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";
import DeviceTokenModal from "./DeviceTokenModal";
import ConfirmationModal from "../../Common/ConfirmationModal";
import UpdateDevicePasswordModal from "./UpdateDevicePasswordModal";


const DeviceList = () => {
    const devices = useSelector((state) => state.devices);
    const dispatch = useDispatch();
    const [openDeviceTokenModal, setOpenDeviceTokenModal] = useState(false);
    const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);
    const [selectedSerialNumber, setSelectedSerialNumber] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const history = useHistory();

    /**Functions for action menus **/

    const editUser = (closeHandler, serialNumber) => {
        closeHandler();
        history.push(`/admin/device/update/${serialNumber}`);
    }

    const generateDeviceToken = (closeHandler, serialNumber) => {
        closeHandler();
        setSelectedSerialNumber(serialNumber);
        setOpenDeviceTokenModal(true);
    }

    const updateDevicePassword = (closeHandler, serialNumber) => {
        closeHandler();
        setSelectedSerialNumber(serialNumber);
        setOpenUpdatePasswordModal(true);
    }

    const modalCloseHandler = (event) => {
        setOpenDeviceTokenModal(false);
        setOpenUpdatePasswordModal(false);
        dispatch(deviceActions.clearDeviceToken());
        setSelectedSerialNumber(null);
    }

    const deleteDevice = (closeHandler, serialNumber) => {
        closeHandler();
        setConfirmationModalOpen(true);
        setSelectedSerialNumber(serialNumber);

    }

    const confirmationModalCloseHandler = (event, proceedAction) => {
        setConfirmationModalOpen(false);
        if (proceedAction) {
            dispatch(deviceActions.deleteDevice(selectedSerialNumber));
            setSelectedSerialNumber(null);
        }
    }
    const search = (value) => {
        dispatch(deviceActions.getAll(value));
    }

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        dispatch(deviceActions.getAll());
        dispatch(alertActions.clear());
        dispatch(surfaceActions.setPageTitle("Device List"));
        dispatch(surfaceActions.showHeaderSearchBar(true));
        dispatch(surfaceActions.setSearchFunc(search));
    }

    const columns = [
        {
            header: "Device",
            field: "SerialNumber",
        },
        {
            header: "Mode",
        },
        {
            header: "Client",
            field: "Client.Name",
        },
        {
            header: "Assigned to Site",
            field: "Site.Name",
        },
        {
            header: "Description",
            field: "Description",
        },
        {
            header: "Actions",
            field: "SerialNumber",
            disableSorting: true,
            actions: [
                {clickListener: editUser, label: 'Edit'},
                {clickListener: deleteDevice, label: 'Delete'},
                {clickListener: generateDeviceToken, label: 'Generate Device Token'},
                {clickListener: updateDevicePassword, label: 'Update Password'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                devices && <DataTable dataCollection={devices} columns={columns}></DataTable>
            }

            <FloatingActionButton path={'/admin/device/new'}/>

            {
                openDeviceTokenModal &&
                <DeviceTokenModal
                    serialNumber={selectedSerialNumber}
                    open={openDeviceTokenModal}
                    closeHandler={modalCloseHandler}/>
            }

            {
                openUpdatePasswordModal &&
                <UpdateDevicePasswordModal
                    serialNumber={selectedSerialNumber}
                    open={openUpdatePasswordModal}
                    closeHandler={modalCloseHandler}/>
            }

            <ConfirmationModal open={confirmationModalOpen} closeHandler={confirmationModalCloseHandler}/>
        </div>
    );
}

export default DeviceList;
