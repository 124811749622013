import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Proptypes from 'prop-types';
import TextField from "@material-ui/core/TextField/TextField";
import {FieldTypes} from '../Common/constants';
import {Controller} from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialUiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles(() => ({
    input: {
        '& .MuiInputBase-root': {
            background: '#FFFFFF'
        },
        marginBottom: '25px'
    },
}));

const Field = (props) => {
    const classes = useStyles();

    return generateField(props, classes);
}

const generateField = (props, classes) => {
    const fieldType = props.type;

    switch (fieldType) {
        case FieldTypes.TEXT_FIELD:
            return (
                <TextField
                    className={classes.input}
                    name={props.name}
                    label={props.label ? props.label : props.name}
                    variant='outlined'
                    inputRef={props.register}
                    fullWidth
                    InputLabelProps={props.isUpdateFlow ? {shrink: true} : null}
                    error={!!props.errors[props.name]}
                    inputProps={{
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off',
                        },
                    }}
                    disabled={props.disabled}
                    helperText={props.errors[props.name]?.message}
                />)
        case FieldTypes.PASSWORD_FIELD:
            return (
                <TextField
                    className={classes.input}
                    name={props.name}
                    label={props.label ? props.label : props.name}
                    variant='outlined'
                    inputRef={props.register}
                    fullWidth
                    type='password'
                    inputProps={{
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off',
                        },
                    }}
                    InputLabelProps={props.isUpdateFlow ? {shrink: true} : null}
                    error={!!props.errors[props.name]}
                    helperText={props.errors[props.name]?.message}
                />)
        case FieldTypes.NUMBER_FIELD:
            return (
                <TextField
                    className={classes.input}
                    name={props.name}
                    label={props.label ? props.label : props.name}
                    variant='outlined'
                    inputRef={props.register}
                    fullWidth
                    type='number'
                    InputLabelProps={props.isUpdateFlow ? {shrink: true} : null}
                    error={!!props.errors[props.name]}
                    helperText={props.errors[props.name]?.message}
                />)
        case FieldTypes.TEXT_AREA:
            return (
                <TextField
                    className={classes.input}
                    name={props.name}
                    label={props.label ? props.label : props.name}
                    variant='outlined'
                    inputRef={props.register}
                    fullWidth
                    multiline
                    rows={props.rows ? props.row : '4'}
                    rowsMax={props.rowsMax ? props.rowsMax : '4'}
                    InputLabelProps={props.isUpdateFlow ? {shrink: true} : null}
                    error={!!props.errors[props.name]}
                    helperText={props.errors[props.name]?.message}
                />)
        case FieldTypes.SELECT_FIELD:
            return (
                <Controller
                    name={props.name}
                    control={props.control}
                    render={controllerProps => (
                        <Autocomplete
                            {...controllerProps}
                            options={props.dropdownOptions}
                            getOptionLabel={props.dropdownOptionLabel}
                            getOptionSelected={props.dropdownOptionSelected}
                            ChipProps={{
                                color: 'primary', size: 'small'
                            }}
                            multiple={props.multiple}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    className={classes.input}
                                    label={props.label}
                                    variant="outlined"
                                    error={!!props.errors[props.name]}
                                    helperText={props.errors[props.name]?.message}
                                />
                            )}
                            onChange={(_, data) => {
                                controllerProps.onChange(data)
                            }}
                        />
                    )}
                />)
        case FieldTypes.PHONE_FIELD:
            return (
                <Controller
                    name={props.name}
                    label={props.label ? props.label : props.name}
                    variant='outlined'
                    fullWidth
                    as={
                        <MaterialUiPhoneNumber
                            defaultCountry={'us'}
                            className={classes.input}
                        />
                    }
                    control={props.control}
                    error={!!props.errors[props.name]}
                    helperText={props.errors[props.name]?.message}
                />)
    }
}


Field.propTypes = {
    type: Proptypes.string.isRequired,
    name: Proptypes.string.isRequired,
    label: Proptypes.string,
    isUpdateFlow: Proptypes.bool,
    errors: Proptypes.object,
    rows: Proptypes.string,
    rowsMax: Proptypes.string,
    control: Proptypes.object,
    dropdownOptions: Proptypes.array,
    dropdownOptionLabel: Proptypes.func,
    dropdownOptionSelected: Proptypes.func,
    multiple: Proptypes.bool,
    disabled: Proptypes.bool
};

export default Field;
