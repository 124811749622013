import React from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    submitBtn: {
        padding: '10px 50px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textTransform: 'capitalize'
    }
}));

const SubmitButton = ({label, formState}) => {
    const classes = useStyles();
    return (
        <Button
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            disabled={!(formState.isValid && formState.isDirty)}
            className={classes.submitBtn}
        >
            {label}
        </Button>
    );
}

export default SubmitButton;
