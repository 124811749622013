import React from "react";
import {Redirect, Route} from "react-router-dom";
import {CssBaseline, makeStyles, Toolbar} from "@material-ui/core";
import AppDrawer from "../NonAdmin/Surface/AppDrawer";
import TopAppBar from "../NonAdmin/Surface/TopAppBar";
import {useSelector} from "react-redux";
import {isLoggedIn} from '../Common/helpers'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        minHeight: '100%'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflowX: 'hidden'
    },
}));

export const PrivateRoute = ({component: Component, leftDrawerRequired, ...rest}) => {
    const classes = useStyles();
    const surface = useSelector((state) => state.surface);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <TopAppBar/>
            {
                surface.leftDrawerRequired && <AppDrawer/>
            }
            <main className={classes.content}>
                <Toolbar/>
                <Route
                    {...rest}
                    render={(props) =>
                        isLoggedIn() ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{pathname: "/login", state: {from: props.location}}}
                            />
                        )
                    }
                />
            </main>
        </div>
    );
};

export default PrivateRoute;
