import React from 'react';
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LabeledText from "../../Common/labeledText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";
import {isDeviceOffline} from "../../Common/helpers";


const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
        '& .MuiCardHeader-action' : {
            margin: '0px'
        }
    },
    gridCard: {
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0)',
        borderRadius: '0px',
        maxHeight: '400px',
        overflow: 'auto'
    },
    offlineStatus: {
        color: '#FFF',
        background: '#f50057',
        padding: '5px 10px',
        borderRadius: '20px',
        fontWeight: 'bold'
    },
    onlineStatus: {
        color: '#FFF',
        background: 'green',
        padding: '5px 10px',
        borderRadius: '20px',
        fontWeight: 'bold'
    },
}));

const DeviceInfoCard = ({device}) => {
    const classes = useStyles();
    const {DeviceInfo} = device;

    return (
        <Card className={classes.gridCard}>
            <CardHeader
                className={classes.cardHeader}
                title="Device Info"
                titleTypographyProps={{variant: 'button'}}
                action={
                    isDeviceOffline(device) ?
                        <Typography className={classes.offlineStatus} variant={"caption"}>OFFLINE</Typography>
                        : <Typography className={classes.onlineStatus} variant={"caption"}>ONLINE</Typography>
                }
            />
            <CardContent>
                {DeviceInfo &&
                <Grid container direction="row" justify="space-between">
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Detector S/N" text={DeviceInfo?.SerialNumber}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Current Mode" text={DeviceInfo?.CurrentModeId}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Internet IP" text={DeviceInfo?.InternetIP}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Software Version" text={DeviceInfo?.SoftwareVersion}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Firmware Version" text={DeviceInfo?.FirmwareVersion}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Explosive Algorithm Version" text={DeviceInfo?.ExplosiveAlgorithmVersion}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Narcotic Algorithm Version" text={DeviceInfo?.NarcoticAlgorithmVersion}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Telit Modem Version" text={DeviceInfo?.TelitModemVersion}/>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <LabeledText label="Matrix Status" text={DeviceInfo?.MatrixStatus}/>
                    </Grid>
                </Grid>
                }
                {!DeviceInfo &&
                <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{textAlign: "center", fontWeight: 'bold'}}
                >
                    No Device Info Found
                </Typography>
                }
            </CardContent>
        </Card>
    );
}

export default DeviceInfoCard;
