import React from 'react';
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LabeledText from "../../Common/labeledText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
    },
    gridCard: {
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0)',
        borderRadius: '0px',
        maxHeight: '400px',
        overflow: 'auto'
    }
}));

const SensorOutputCard = ({device}) => {
    const {ServiceInfo} = device;
    const {DeviceInfo} = device;
    const classes = useStyles();

    return (
        <Card className={classes.gridCard}>
            <CardHeader
                className={classes.cardHeader}
                title="Sensor Output"
                titleTypographyProps={{variant: 'button'}}
            />
            <CardContent>
                {ServiceInfo && <Grid container direction="row">
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 1" text={DeviceInfo?.Sensor1}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 2" text={DeviceInfo?.Sensor2}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 3" text={DeviceInfo?.Sensor3}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 4" text={DeviceInfo?.Sensor4}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 5" text={DeviceInfo?.Sensor5}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 6" text={DeviceInfo?.Sensor6}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 7" text={DeviceInfo?.Sensor7}/>
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledText label="Sensor 8" text={DeviceInfo?.Sensor8}/>
                    </Grid>
                    <Grid item xs={3} >
                        <LabeledText label="T1 Temperature" text={DeviceInfo?.T1Temperature}/>
                    </Grid>
                    <Grid item xs={3} >
                        <LabeledText label="T2 Temperature" text={DeviceInfo?.T2Temperature}/>
                    </Grid>
                </Grid>
                }
                {!ServiceInfo &&
                <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{textAlign: "center", fontWeight: 'bold'}}
                >
                    No Sensor Output Found
                </Typography>
                }
            </CardContent>
        </Card>
    );
}

export default SensorOutputCard;
