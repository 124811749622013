import {authHeader} from "../_helpers/auth-header";
import {handleResponse} from "./helper.service";
import config from "../config";

function getByDeviceSerialNumber(deviceSerial) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/devices/${deviceSerial}/errors`, requestOptions)
        .then(handleResponse);
}


export const errorsService = {
    getByDeviceSerialNumber
}
