import React from "react";
import {useHistory} from 'react-router-dom';
import PropTypes from "prop-types";
import {Divider, Grid, Link, makeStyles, Paper, Typography,} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    cardPaper: {
        padding: theme.spacing(2),
    },
}));

function InfoCard(props) {
    const {title, text, icon, link, linkText, ...other} = props;
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <Paper className={classes.cardPaper}>
                <Grid container direction="column" justify="space-around" alignItems="stretch" spacing={1}>
                    <Grid item container direction="row" spacing={4} alignItems="center">
                        <Grid item xs>
                            {React.createElement(icon, {
                                fontSize: "large",
                                color: "primary",
                            })}
                        </Grid>
                        <Grid item xs container direction="column" alignItems="flex-end">
                            <Grid item>
                                <Typography variant="caption" noWrap>{title}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4">{text}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider/>
                    </Grid>
                    <Grid item>
                        <Link onClick={() => history.push(link)} style={{cursor: 'pointer'}}>{linkText}</Link>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

InfoCard.PropType = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default InfoCard;
