import {devicesService} from "../_services";
import {history} from "../_helpers";
import {alertActions} from "./alert.actions";
import {deviceConstants} from '../_constants'
import {loaderActions} from "./loader.actions";

function getAll(searchVal) {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.getAll().then(
            (response) => {
                let devices = response.data ? response.data.Items : [];
                if(searchVal) {
                    dispatch(getAllSuccess(filterResult(devices, searchVal)));
                } else {
                    dispatch(getAllSuccess(devices));
                }
                dispatch(loaderActions.clear());
            }, (error) => dispatchError(error, dispatch));
    }

}

function filterResult (devices, searchVal) {
    return devices.filter(item => {
        return item.SerialNumber?.toLowerCase().includes(searchVal.toLowerCase())
            || item.Description?.toLowerCase().includes(searchVal.toLowerCase())
    });
}

function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.getSingle(id).then(
            (deviceResp) => {
                dispatch(getSingleSuccess(deviceResp));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            });
    }
}


function save(data, isUpdate) {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/devices');
                const message = isUpdate ? 'Device updated successfully' : 'Device saved successfully';
                dispatch(alertActions.success(message))
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function updatePassword(data, closeHandler) {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.save(data, true).then(
            (response) => {
                dispatch(alertActions.success('Password Updated Successfully'));
                dispatch(loaderActions.clear());
                closeHandler();
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function getBySite(siteId) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        devicesService.getBySite(siteId).then(
            (response) => {
                const devices = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(devices))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function getByClient(clientId) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        devicesService.getByClient(clientId).then(
            (response) => {
                const devices = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(devices))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function deleteDevice(serialNumber) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        devicesService.deleteDevice(serialNumber).then(
            (response) => {
                dispatch(deviceActions.getAll());
                dispatch(alertActions.success('Device Deleted Successfully'));
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function deviceLogin(username, password) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        devicesService.deviceLogin(username, password).then(
            (response) => {
                dispatch(deviceLoginResponse(response));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
}


function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function hideDashboardDeviceCard(hideFlag) {
    return {type: deviceConstants.DEVICES_CARD_TOGGLE, hideFlag};
}

function getAllSuccess(devices) {

    return {type: deviceConstants.GET_ALL_SUCCESS, devices};
}

function deviceLoginResponse(response) {

    return {type: deviceConstants.DEVICE_LOGIN_REQUEST, response};
}

function saveSuccess(device) {

    return {type: deviceConstants.SAVE_SUCCESS, device};
}

function getSingleSuccess(device) {

    return {type: deviceConstants.GET_SINGLE_SUCCESS, device};
}

function failure(error) {

    return {type: deviceConstants.FAILURE, error};
}
function clearDeviceToken() {

    return {type: deviceConstants.DEVICE_LOGIN_CLEAR_TOKEN};
}

export const deviceActions = {
    getAll,
    save,
    getSingle,
    hideDashboardDeviceCard,
    getBySite,
    getByClient,
    deviceLogin,
    clearDeviceToken,
    deleteDevice,
    updatePassword
};
