import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, surfaceActions, usersActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";
import ConfirmationModal from "../../Common/ConfirmationModal";
import UserTokenModal from "./UserTokenModal";


const SiteList = () => {
    const users = useSelector((state) => state.users);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [openUserTokenModal, setOpenUserTokenModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        dispatch(surfaceActions.showHeaderSearchBar(true));
        dispatch(surfaceActions.setPageTitle("User List"));
        dispatch(usersActions.getList());
        dispatch(alertActions.clear());
        dispatch(surfaceActions.setSearchFunc(search));
    }

    const search = (value) => {
        dispatch(usersActions.getList(value));
    }
    /**Functions for action menus **/

    const editUser = (closeHandler, userId) => {
        closeHandler();
        history.push(`/admin/user/update/${userId}`);
    }

    const deleteUser = (closeHandler, userId) => {
        closeHandler();
        setConfirmationModalOpen(true);
        setSelectedUserId(userId);

    }

    const confirmationModalCloseHandler = (event, proceedAction) => {
        setConfirmationModalOpen(false);
        if (proceedAction) {
            dispatch(usersActions.deleteUser(selectedUserId));
            setSelectedUserId(null);
        }
    }

    const generateUserToken = (closeHandler, userId) => {
        closeHandler();
        setSelectedUserId(userId);
        setOpenUserTokenModal(true);
    }

    const modalCloseHandler = (event) => {
        setOpenUserTokenModal(false);
        dispatch(usersActions.clearUserToken());
    }

    const columns = [
        {
            header: "Username",
            field: "User",
        },
        {
            header: "Name",
            field: "Name",
        },
        {
            header: "Clients",
            field: "Clients",
            array: true,
            nested: true,
            nestedObjAttribute: 'Name'
        },
        {
            header: "Sites",
            field: "Sites",
            array: true,
            nested: true,
            nestedObjAttribute: 'Name'
        },
        {
            header: "Role",
            field: "Roles",
            array: true
        },
        {
            header: "Email",
            field: "Email",
        },
        {
            header: "Actions",
            field: "User",
            disableSorting: true,
            actions: [
                {clickListener: editUser, label: 'Edit'},
                {clickListener: deleteUser, label: 'Delete'},
                {clickListener: generateUserToken, label: 'Generate Token'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                users ?
                    <DataTable dataCollection={users} columns={columns}></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/user/new'}/>
            {
                openUserTokenModal &&
                <UserTokenModal
                    username={selectedUserId}
                    open={openUserTokenModal}
                    closeHandler={modalCloseHandler}/>
            }
            <ConfirmationModal open={confirmationModalOpen} closeHandler={confirmationModalCloseHandler}/>
        </div>
    );
}

export default SiteList;
