import {authHeader} from "../_helpers";
import {handleResponse} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/sites`, requestOptions).then(
        handleResponse
    );
}

function getSingle(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/sites/${id}`, requestOptions).then(
        handleResponse
    );
}

const save = (siteJson, isUpdate) => {

    let url = `${config.getAdminApiBaseUrl()}/sites`;

    if (isUpdate) {
        url = `${url}/${siteJson.SiteId}`;
        // converting into desired format
        //e.g {update: {key:value}}
        siteJson = {
            update: {
                ...siteJson
            }
        }
    }

    const requestOptions = {
        method: isUpdate ? 'PUT' : 'POST',
        headers: authHeader(),
        body: JSON.stringify(siteJson)
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function deleteSite(siteId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

    return fetch(`${config.getAdminApiBaseUrl()}/sites/${siteId}`, requestOptions).then(
        handleResponse
    );
}


export const sitesService = {
    getAll,
    getSingle,
    save,
    deleteSite
};
