import {authHeader} from "../_helpers";
import {handleResponse, tokenLocalStorageName} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/devices`, requestOptions).then(
        handleResponse
    );
}

function getSingle(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/devices/${id}`, requestOptions).then(
        handleResponse
    );
}

const save = (deviceJson, isUpdate) => {

    let url = `${config.getAdminApiBaseUrl()}/devices`;

    if (isUpdate) {
        url = `${url}/${deviceJson.SerialNumber}`;
        // converting into desired format
        //e.g {update: {key:value}}
        deviceJson = {
            update: {
                ...deviceJson
            }
        }
    }

    const requestOptions = {
        method: isUpdate ? 'PUT' : 'POST',
        headers: authHeader(),
        body: JSON.stringify(deviceJson)
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function getBySite(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/sites/${id}/devices`, requestOptions).then(
        handleResponse
    );
}

function getByClient(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/clients/${id}/devices`, requestOptions).then(
        handleResponse
    );
}

function deviceLogin(username, password) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({user: username, password}),
    };

    return fetch(`${config.getDeviceApiBAseUrl()}/login`, requestOptions)
        .then((response) => {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
}

function deleteDevice(serialNumber) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

    return fetch(`${config.getAdminApiBaseUrl()}/devices/${serialNumber}`, requestOptions).then(
        handleResponse
    );
}


export const devicesService = {
    getAll,
    getSingle,
    save,
    getBySite,
    getByClient,
    deviceLogin,
    deleteDevice
};
