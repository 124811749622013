import React from "react";
import {GoogleMap, Marker,} from "@react-google-maps/api";
import PropTypes from "prop-types";

const defaultMapContainerStyle = {
    height: "220px",
    width: "100%"
}

const options = {
    disableDefaultUI: true,
    zoomControl: false,
    fullscreenControl: true,
    scaleControl: true,

}

const defaultCenter = {
    lat: 33.748997,
    lng: -84.387985
}
const DeviceGeneralMap = ({coordinates, mapContainerStyle}) => {

    const getMarker = () => {
        const position = getPosition();
        return position ?
            <Marker position={position}/>
            : null;
    }

    const getPosition = () => {
        if (!coordinates) {
            return null;
        }
        const latLongArray = coordinates.split(',');
        if (!(latLongArray && latLongArray.length === 2)) {
            return null;
        }
        const position = {lat: +latLongArray[0].trim(), lng: +latLongArray[1].trim()}
        return position;
    }

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle ? mapContainerStyle : defaultMapContainerStyle}
            zoom={9}
            center={getPosition() || defaultCenter}
            options={options}
        >
            {
                getMarker()
            }
        </GoogleMap>
    );
};

DeviceGeneralMap.PropType = {
    coordinates: PropTypes.string.isRequired,
    mapContainerStyle: PropTypes.object

};

export default DeviceGeneralMap;
