import React from "react";
import {Box, ButtonBase, Grid, IconButton, makeStyles, Paper, Popover, Typography,} from "@material-ui/core";
import {
    Apps as AppsIcon,
    Dashboard as DashboardIcon,
    LocationOn as LocationOnIcon,
    Person as PersonIcon,
    Public as PublicIcon,
    Settings as SettingsIcon,
    Smartphone as SmartphoneIcon,
    SupervisorAccount as SupervisorAccountIcon,
} from "@material-ui/icons";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        // background: "deeppink",
    },
    iconButtonRoot: {
        // padding: theme.spacing(2),
        textAlign: "center",
    },
    iconContainer: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    label: {
        fontSize: 11,
        margin: theme.spacing(1),
    },
    icon: {
        margin: "auto",
        display: "flex",
    },
}));

//Routes configuration for menu item
const menuRoutes = {
    adminDashboard: {label: 'Admin Dashboard', routeTo: '/admin', icon: DashboardIcon},
    user: {label: 'Users', routeTo: '/admin/users', icon: PersonIcon},
    site: {label: 'Sites', routeTo: '/admin/sites', icon: LocationOnIcon},
    device: {label: 'Devices', routeTo: '/admin/devices', icon: SmartphoneIcon},
    client: {label: 'Clients', routeTo: '/admin/clients', icon: SupervisorAccountIcon},
    region: {label: 'Regions', routeTo: '/admin/regions', icon: PublicIcon},
    model: {label: 'Models', routeTo: '/admin/models', icon: SettingsIcon},
}



function AppsMenu(props) {
    const {...other} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const classes = useStyles();

    const AppIcon = ({label, routeTo, icon: IconComponent}) => {
        const classes = useStyles();

        return (
            <div className={classes.iconButtonRoot}>
                <ButtonBase focusRipple className={classes.iconContainer} component={Link} to={routeTo} onClick={() => setAnchorEl(null)}>
                    <Box>
                        <IconComponent className={classes.icon} fontSize='large'/>
                        <Typography variant="button" display="block" className={classes.label}>
                            {label}
                        </Typography>
                    </Box>
                </ButtonBase>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <IconButton color="inherit" onClick={handleOpenMenu}>
                <AppsIcon/>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Paper>
                    <Grid container direction="row">
                        <Grid
                            item
                            xs={6}
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                {AppIcon(menuRoutes.adminDashboard)}
                            </Grid>
                            <Grid item>
                                {AppIcon(menuRoutes.user)}
                            </Grid>
                            <Grid item>
                                {AppIcon(menuRoutes.site)}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                {AppIcon(menuRoutes.client)}
                            </Grid>
                            <Grid item>
                                {AppIcon(menuRoutes.region)}
                            </Grid>
                            <Grid item>
                                {AppIcon(menuRoutes.device)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Popover>
        </div>
    );
}

export default AppsMenu;
