import {deviceConstants, userConstants} from "../_constants/";

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_ALL_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.SAVE_SUCCESS:
      return {
        item: action.user,
      };
    case userConstants.GET_SINGLE_SUCCESS:
      return {
        ...state,
        item: action.user.data,
      };
    case userConstants.USER_LOGIN_REQUEST:
      return {
        ...state,
        loginResponse: action.response,
      };
    case userConstants.USER_LOGIN_CLEAR_TOKEN:
      return {
        ...state,
        loginResponse: undefined,
      };
    case userConstants.FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

