import {authHeader} from "../_helpers";
import {handleResponse} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/clients`, requestOptions).then(
        handleResponse
    );
}

function getSingle(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/clients/${id}`, requestOptions).then(
        handleResponse
    );
}

const save = (clientJson, isUpdate) => {
    let url;
    if (isUpdate) {
        url = `${config.getAdminApiBaseUrl()}/clients/${clientJson.sk}`;
        // converting into desired format
        //e.g {update: {key:value}}
        delete clientJson['sk'];
        clientJson = {
            update: {
                ...clientJson
            }
        }
    } else {
        url = `${config.getAdminApiBaseUrl()}/clients`;
    }

    const requestOptions = {
        method: isUpdate ? 'PUT' : 'POST',
        headers: authHeader(),
        body: JSON.stringify(clientJson)
    }


    return fetch(url, requestOptions)
        .then(handleResponse)
}

function deleteClient(clientId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

    return fetch(`${config.getAdminApiBaseUrl()}/clients/${clientId}`, requestOptions).then(
        handleResponse
    );
}


export const clientsService = {
    getAll,
    getSingle,
    save,
    deleteClient
};
