import {errorConstants} from "../_constants";

export function errors(state = {}, action) {
    switch (action.type) {
        case errorConstants.REQUEST:
            return {
                loading: true,
            };
        case errorConstants.GET_ALL_SUCCESS:
            return {
                items: action.errors,
            };
        case errorConstants.FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
