import React from 'react';
import Dashboard from "./Dashboard/Dashboard";
import DeviceTabContainer from "./Device/DeviceTabContainer";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {Route} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpenState: {
        // marginLeft: '-286px'
    },
}));

const FlowNonAdmin = () => {
    const classes = useStyles();
    const surface = useSelector((state) => state.surface);

    return (
        <div className={clsx(classes.root, {[classes.drawerOpenState]: !surface.drawerOpen})}>
            <Route path={['/non-admin/', '/non-admin/dashboard']} exact component={Dashboard}/>
            <Route path='/non-admin/device/detail/:serialNumber' exact component={DeviceTabContainer}/>
        </div>
    );
}

export default FlowNonAdmin;
