import React from "react";
import {Redirect, Route} from "react-router-dom";
import {CssBaseline, makeStyles, Toolbar} from "@material-ui/core";
import TopAppBar from "../Admin/Surface/TopAppBar";
import {isAdministrator} from '../Common/helpers'

const useStyles = makeStyles((theme) => {
    theme.palette.background.default = theme.palette.secondary.light;
    return {
        root: {
            display: "flex",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    };
});

export const AdminRoute = ({component: Component, ...rest}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <TopAppBar/>
            <main className={classes.content}>
                <Toolbar/>
                <Route
                    {...rest}
                    render={(props) =>
                        isAdministrator() ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{pathname: "/login", state: {from: props.location}}}
                            />
                        )
                    }
                />
            </main>
        </div>
    );
};

export default AdminRoute;
