import React, {Fragment, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers';
import {clientsActions, sitesActions, surfaceActions, usersActions} from "../../_actions";
import BackLinkButton from "../../_components/BackLinkButton";
import Grid from "@material-ui/core/Grid";
import Field from "../../_components/Field";
import {FieldTypes} from "../../Common/constants";
import SubmitButton from "../../_components/SubmitButton";
import {getClientOptions, getSiteOptions, getSitesByClients} from "../../Common/helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'center'
    },
    passCheckBox: {
        marginBottom: '10px',
        marginTop: '-10px',
        width: '100%'
    },
}));


const AddUser = ({location}) => {

    const clients = useSelector(state => state.clients.items);
    const userObj = useSelector(state => state.users.item);
    const sites = useSelector(state => state.sites.items);
    const isUpdateFlow = location.pathname.includes('update');
    const [isPasswordUpdate, setIsPasswordUpdate] = useState(false);
    const {userId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();

    const validationRules = {
        User: yup.string().required('Username is required'),
        Name: yup.string().required('Full Name is required'),
        Clients: yup.array().min(1, 'Client is required'),
        Roles: yup.array().min(1, 'At least one role is required'),
        PhoneNo: yup.string().required('Phone number is required'),
        Email: yup.string().required('Email is required').email('Invalid email address'),
        Description: yup.string().required('Description is required')
    }

    if (!isPasswordUpdate && isUpdateFlow) {
        delete validationRules.Password;
    } else {
        validationRules.Password = yup.string().required('Password is required');
    }

    const validationSchema = yup.object().shape(validationRules);

    const {register, handleSubmit, errors, control, formState, reset, watch} = useForm({
        defaultValues: {
            User: '',
            Name: '',
            Email: '',
            Password: '',
            Description: '',
            PhoneNo: '',
            Clients: [],
            Sites: [],
            Roles: []
        },
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),

    });

    const watchClient = watch('Clients');
    const submitHandler = (data) => {
        const {Clients} = data;
        data['Clients'] = Clients.map(client => client.ClientId);
        const {Sites} = data;
        data['Sites'] = Sites.map(site => site.SiteId);
        if (userId) {
            data['User'] = userId;
            dispatch(usersActions.save(data, true));
        } else {
            dispatch(usersActions.save(data, false));
        }
    };


    //Setting loaded user object for update case
    useEffect(() => {
        if (userObj) {
            if (userObj && (!userObj.hasOwnProperty('Sites') || !userObj.Sites)) {
                userObj['Sites'] = [];
            }
            reset(userObj);
        }
    }, [userObj])


    useEffect(() => {
        dispatch(surfaceActions.showHeaderSearchBar(false));
        dispatch(clientsActions.getList());
        dispatch(sitesActions.getList());
        if (userId) {
            dispatch(usersActions.getSingle(userId));
            dispatch(surfaceActions.setPageTitle("Update User"));
        } else {
            dispatch(surfaceActions.setPageTitle("Add User"));
        }
    }, [])

    const getRoleOptions = () => {
        return ['Administrator', 'Manager', 'Technician', 'Operator', "Director", "Supervisor"];
    };

    const checkboxChangeHandler = () => {
        setIsPasswordUpdate(!isPasswordUpdate);
    }

    const getPasswordField = () => {
        if (!isUpdateFlow) {
            return (<Field
                type={FieldTypes.PASSWORD_FIELD}
                name='Password'
                label='Password'
                register={register}
                errors={errors}
                isUpdateFlow={isUpdateFlow}
            />);
        }

        return (
            <Fragment>
                <FormControlLabel
                    control={<Checkbox checked={isPasswordUpdate} onChange={checkboxChangeHandler}
                                       name="passwordUpdateFlag" color={'primary'}/>}
                    label="Check to update password"
                    className={classes.passCheckBox}
                />
                {isPasswordUpdate &&
                <Field
                    type={FieldTypes.PASSWORD_FIELD}
                    name='Password'
                    label='Password'
                    register={register}
                    errors={errors}
                />}
            </Fragment>)
    }

    return (

        <Fragment>
            <Box>
                <BackLinkButton path={'/admin/users'}/>
                <Container maxWidth='sm'>
                    <form onSubmit={handleSubmit(submitHandler)} autoComplete='off'>
                        <Box className={classes.fieldContainer}>
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='User'
                                label='Username'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            {getPasswordField()}
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='Name'
                                label='Full Name'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Clients'
                                control={control}
                                errors={errors}
                                dropdownOptions={getClientOptions(clients)}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option.ClientId === value.ClientId}
                                label='Select Clients'
                                multiple={true}
                            />
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Sites'
                                control={control}
                                errors={errors}
                                dropdownOptions={(watchClient.length) ? getSitesByClients(sites, watchClient) : getSiteOptions(sites)}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option && value && option.SiteId === value.SiteId}
                                label='Assigned to Sites'
                                multiple={true}
                            />
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Roles'
                                control={control}
                                errors={errors}
                                dropdownOptions={getRoleOptions()}
                                dropdownOptionLabel={option => option}
                                dropdownOptionSelected={(option, value) => option === value}
                                label='Select Roles'
                                multiple={true}
                            />

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type={FieldTypes.PHONE_FIELD}
                                        name='PhoneNo'
                                        label={'Phone Number'}
                                        control={control}
                                        errors={errors}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        type={FieldTypes.TEXT_FIELD}
                                        name='Email'
                                        register={register}
                                        errors={errors}
                                        isUpdateFlow={isUpdateFlow}
                                    />
                                </Grid>
                            </Grid>
                            <Field
                                type={FieldTypes.TEXT_AREA}
                                name='Description'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <SubmitButton
                                formState={formState}
                                label={isUpdateFlow ? 'UPDATE' : 'ADD'}
                            />

                        </Box>
                    </form>
                </Container>
            </Box>
        </Fragment>
    );
};

export default AddUser;
