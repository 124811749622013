import React, {useEffect} from "react";
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, errorActions} from "../../_actions";
import DateTimeView from "../../Common/dateTimeView";
import PropTypes from "prop-types";


const TabPanelErrors = ({serialNumber}) => {
    const errors = useSelector((state) => state.errors);
    const dispatch = useDispatch();

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        dispatch(errorActions.getByDevice(serialNumber));
        dispatch(alertActions.clear());
    }

    const columns = [
        {
            header: "Serial Number",
            field: "SerialNumber",
        },
        {
            header: "Maintenance Code",
            field: "ErrorCode",
        },
        {
            header: "Maintenance Description",
            field: "ErrorDescription",
        },
        {
            header: "DATE AND TIME",
            field: "DateTime",
            template: DateTimeView,
            parameters: {
                dateTime: "#field#"
            }
        }
    ];

    return (
        <div>
            {
                errors ?
                    <DataTable
                        dataCollection={errors}
                        columns={columns}
                        paginationNotRequired={true}
                        size={'medium'}
                    />
                    : null
            }
        </div>
    );
}

TabPanelErrors.PropType = {
    serialNumber: PropTypes.string.isRequired
};

export default TabPanelErrors;
