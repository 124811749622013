import React, {useEffect} from 'react';
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LabeledText from "../../Common/labeledText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, modeActions} from "../../_actions";
import DataTable from "../../_components/DataTable";
import DateTimeView from "../../Common/dateTimeView";
import LocationIcon from "../../Common/LocationIcon";
import ImagePopoverIcon from "../../Common/ImagePopoverIcon";
import VideoPopoverIcon from "../../Common/VideoPopoverIcon";


const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
    },
    gridCard: {
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0)',
        borderRadius: '0px',
        maxHeight: '400px',
        overflow: 'auto'
    }
}));

const ResultCard = ({device}) => {
    const modes = useSelector((state) => state.modes);
    const {SerialNumber, DeviceInfo} = device;
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (DeviceInfo) {
            dispatch(modeActions.getModesById(SerialNumber, DeviceInfo.CurrentModeId));
        }
        dispatch(alertActions.clear());
    }, [SerialNumber]);

    const columns = [
        {
            header: "Result",
            field: "ResultId",
        },
        {
            header: "#",
            field: "ResultValue",
        }
    ];
    return (
        <Card className={classes.gridCard}>
            <CardHeader
                className={classes.cardHeader}
                title="Result"
                titleTypographyProps={{variant: 'button'}}
            />
            <CardContent>
                {
                    modes && modes.items &&
                    <DataTable
                            dataCollection={modes}
                            columns={columns}
                            paginationNotRequired={true}
                            // rowCssClass={classes.rowCssClass}
                        />
                }
                {!modes || !modes.items &&
                <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{textAlign: "center", fontWeight: 'bold'}}
                >
                    No Mode Result Found
                </Typography>
                }
            </CardContent>
        </Card>
    );
}

export default ResultCard;
