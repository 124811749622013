export const alarmsConstants = {

    GET_ALL_SUCCESS: "ALARMS_GET_ALL_SUCCESS",

    GET_SINGLE_SUCCESS: "ALARMS_GET_SINGLE_SUCCESS",

    REQUEST: "ALARMS_REQUEST",
    SAVE_SUCCESS: "ALARMS_SAVE_SUCCESS",
    FAILURE: "ALARMS_FAILURE",
};
