import { clientsConstants } from "../_constants/clients.constants";

export function clients(state = {}, action) {
  switch (action.type) {
    case clientsConstants.REQUEST:
      return {
        loading: true,
      };
    case clientsConstants.GET_ALL_SUCCESS:
      return {
        items: action.clients,
      };
    case clientsConstants.SAVE_SUCCESS:
      return {
        item: action.client,
      };
    case clientsConstants.GET_SINGLE_SUCCESS:
      return {
        item: action.client.data,
      };
    case clientsConstants.FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

