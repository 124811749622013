import {modesConstants} from "../_constants";
import {alertActions, loaderActions} from "./";
import {modeService} from '../_services'

export const modeActions = {
    getModesById
};


function getModesById(serialNumber, modeId) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        modeService.getByDeviceAndModeId(serialNumber, modeId).then(
            (response) => {
                const modes = response.data ? response.data?.Results?.Results : [];
                dispatch(getAllSuccess(modes))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function getAllSuccess(modes) {
    return {type: modesConstants.GET_ALL_SUCCESS, modes};
}

function failure(error) {
    return {type: modesConstants.FAILURE, error};
}
