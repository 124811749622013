import React, {useEffect} from 'react';
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, healthActions} from "../../_actions";
import DataTable from "../../_components/DataTable";
import DateTimeView from "../../Common/dateTimeView";


const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
    },
    gridCard: {
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0)',
        borderRadius: '0px',
        maxHeight: '400px',
        overflow: 'auto'
    }
}));

const HealthChecksCard = ({device}) => {
    const healthChecks = useSelector((state) => state.healths);
    const {SerialNumber} = device;
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(healthActions.getByDevice(SerialNumber));
        dispatch(alertActions.clear());
    }, [SerialNumber]);

    const columns = [
        {
            header: "Generated At",
            field: "CreatedAt",
            template: DateTimeView,
            parameters: {
                dateTime: "#field#"
            }
        }
    ];
    return (
        <Card className={classes.gridCard}>
            <CardHeader
                className={classes.cardHeader}
                title="Health Checks"
                titleTypographyProps={{variant: 'button'}}
            />
            <CardContent>
                {
                    healthChecks && healthChecks.items &&
                    <DataTable
                        dataCollection={healthChecks}
                        columns={columns}
                        paginationNotRequired={true}
                        // rowCssClass={classes.rowCssClass}
                    />
                }
                {!healthChecks || !healthChecks.items &&
                <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{textAlign: "center", fontWeight: 'bold'}}
                >
                    No Health Checks Found
                </Typography>
                }
            </CardContent>
        </Card>
    );
}

export default HealthChecksCard;
