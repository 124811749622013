import React from 'react';
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import {Add as AddIcon} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    fab: {position: 'fixed', bottom: theme.spacing(5), right: theme.spacing(5)}

}));
const FloatingActionButton = ({path}) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Fab color="primary" className={classes.fab}  onClick={() => {
            history.push(path);
        }}>
            <AddIcon/>
        </Fab>
    );
}

export default FloatingActionButton;