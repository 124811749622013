import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import {Box, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {Close as CancelIcon} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {deviceActions} from "../../_actions";
import {useDispatch, useSelector} from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Field from "../../_components/Field";
import {FieldTypes} from "../../Common/constants";
import DialogActions from "@material-ui/core/DialogActions";
import SubmitButton from "../../_components/SubmitButton";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'center'
    },
    dialogAction: {
        padding: '44px'
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        paddingLeft: '15px',
        paddingRight: '15px',
        borderBottom: '1px solid #eee'
    },
    headerTitle: {
        paddingTop: '9px'
    },
    tokenDiv: {
        marginBottom: '30px',
        wordWrap: 'break-word'
    },
    copyBtn: {
        padding: '10px 50px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textTransform: 'capitalize',
        marginBottom: '12px'
    },
    input: {
        '& .MuiInputBase-root': {
            background: '#FFFFFF'
        },
        marginBottom: '25px'

    },
}));


const UpdateDevicePasswordModal = ({open, closeHandler, serialNumber}) => {
    const classes = useStyles();
    const loginResponse = useSelector((state) => state.devices?.loginResponse);
    const selectedDeviceUsername = useSelector((state) => state.devices?.item?.User ? state.devices?.item?.User : '');
    const [openAlert, setOpenAlert] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        closeHandler();
    };
    const validationRules = {
        password: yup.string().required('Password is required')
    }
    const validationSchema = yup.object().shape(validationRules);
    const {register, handleSubmit, errors, formState} = useForm({
        defaultValues: {
            password: null
        },
        mode: 'onChange',
        resolver: yupResolver(validationSchema),

    });

    const validationRulesUpdatePassForm = {
        newPassword: yup.string().required('New Password is required'),
        confirmPassword: yup.string().required('Confirm Password is required')
    }
    const validationSchemaUpdatePassForm = yup.object().shape(validationRulesUpdatePassForm);
    const {register: registerUpdatePass, handleSubmit: handleSubmitUpdatePass, errors: errorsUpdatePass, formState: formStateUpdatePass} = useForm({
        defaultValues: {
            newPassword: null,
            confirmPassword: null,
        },
        mode: 'onChange',
        resolver: yupResolver(validationSchemaUpdatePassForm),

    });

    const submitHandler = (data) => {
        dispatch(deviceActions.deviceLogin(selectedDeviceUsername, data.password));
    };

    const submitUpdatePasswordHandler = (data) => {
        if (data.newPassword !== data.confirmPassword) {
            setOpenAlert(true);
            return;
        }

        let requestPaylaod = {
            'SerialNumber': serialNumber,
            'Password': data.newPassword
        }
        dispatch(deviceActions.updatePassword(requestPaylaod, closeHandler));
    };

    useEffect(() => {
        setOpenAlert(loginResponse && !loginResponse.ok);
    }, [loginResponse])

    useEffect(() => {
        dispatch(deviceActions.getSingle(serialNumber));
    }, [serialNumber])

    return (
        <div>
            <Dialog
                open={open}
            >
                <div className={classes.dialogHeader}>
                    <Typography variant={'h6'} className={classes.headerTitle}>
                        {
                            loginResponse?.ok ? 'Update Device Password' : 'Verify Existing Password'
                        }
                    </Typography>
                    <IconButton aria-label="cancel" onClick={handleClose}>
                        <CancelIcon/>
                    </IconButton>
                </div>
                <DialogContent>
                    <Container maxWidth='sm'>
                        {
                            loginResponse?.ok &&
                            <form autoComplete='off' onSubmit={handleSubmitUpdatePass(submitUpdatePasswordHandler)}>
                                <Box className={classes.fieldContainer}>
                                    {
                                        openAlert &&
                                        <Alert
                                            variant={'filled'}
                                            severity={'error'}
                                            onClose={() => setOpenAlert(false)}
                                            style={{marginBottom: '25px'}}
                                        >
                                            Password doesn't match!
                                        </Alert>

                                    }
                                    <Field
                                        type={FieldTypes.PASSWORD_FIELD}
                                        name='newPassword'
                                        label='New Password'
                                        register={registerUpdatePass}
                                        errors={errorsUpdatePass}
                                    />
                                    <Field
                                        type={FieldTypes.PASSWORD_FIELD}
                                        name='confirmPassword'
                                        label='Confirm Password'
                                        register={registerUpdatePass}
                                        errors={errorsUpdatePass}
                                    />
                                </Box>
                                <DialogActions className={classes.dialogAction}>
                                    <SubmitButton
                                        label={'Update Password'}
                                        formState={formStateUpdatePass}
                                    />
                                </DialogActions>
                            </form>
                        }
                        {
                            !loginResponse?.ok &&
                            <form autoComplete='off' onSubmit={handleSubmit(submitHandler)}>
                                <Box className={classes.fieldContainer}>
                                    {
                                        openAlert &&
                                        <Alert
                                            variant={'filled'}
                                            severity={'error'}
                                            onClose={() => setOpenAlert(false)}
                                            style={{marginBottom: '25px'}}
                                        >
                                            {loginResponse?.error}
                                        </Alert>

                                    }
                                    <TextField
                                        className={classes.input}
                                        variant='outlined'
                                        fullWidth
                                        inputProps={{
                                            autoComplete: 'new-password',
                                            form: {
                                                autoComplete: 'off',
                                            },
                                        }}
                                        disabled={true}
                                        value={selectedDeviceUsername}
                                    />
                                    <Field
                                        type={FieldTypes.PASSWORD_FIELD}
                                        name='password'
                                        label='Password'
                                        register={register}
                                        errors={errors}
                                    />
                                </Box>
                                <DialogActions className={classes.dialogAction}>
                                    <SubmitButton
                                        label={'Verify'}
                                        formState={formState}
                                    />
                                </DialogActions>
                            </form>
                        }
                    </Container>
                </DialogContent>

            </Dialog>
        </div>
    );
}

UpdateDevicePasswordModal.PropType = {
    open: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired,
    serialNumber: PropTypes.string.isRequired
}

export default UpdateDevicePasswordModal;
