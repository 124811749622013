import { loaderConstants } from "../_constants";

export const loaderActions = {
  isLoading,
  clear,
};

function isLoading() {
  return { type: loaderConstants.IS_LOADING };
}

function clear() {
  return { type: loaderConstants.CLEAR };
}
