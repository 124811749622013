import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from "prop-types";
import {Box, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Close as CancelIcon} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'left'
    },
    dialogAction: {
        padding: '44px'
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        paddingLeft: '15px',
        paddingRight: '15px',
        borderBottom: '1px solid #eee'
    },
    headerTitle: {
        paddingTop: '9px'
    },
    tokenDiv: {
        marginBottom: '30px',
        wordWrap: 'break-word'
    },
    okBtn: {
        textTransform: 'capitalize',
        marginBottom: '12px',
        marginRight: '5px'
    },
    cancelBtn: {
        textTransform: 'capitalize',
        marginBottom: '12px',
    },
    input: {
        '& .MuiInputBase-root': {
            background: '#FFFFFF'
        },
        marginBottom: '25px'

    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));


const ConfirmationModal = ({open, closeHandler}) => {
    const classes = useStyles();
    const handleClose = (event) => {
        closeHandler(event, false);
    };

    const handleOk = (event) => {
        closeHandler(event, true);
    };

    return (
        <div>
            <Dialog
                open={open}
            >
                <div className={classes.dialogHeader}>
                    <Typography variant={'h6'} className={classes.headerTitle}>Alert</Typography>
                    <IconButton aria-label="cancel" onClick={handleClose}>
                        <CancelIcon/>
                    </IconButton>
                </div>
                <DialogContent>
                    <Box className={classes.fieldContainer}>
                        <Typography variant={'body2'} className={classes.tokenDiv}>
                            Are you really want to proceed with this action?
                        </Typography>

                        <Box className={classes.btnContainer}>
                            <Button
                                type='button'
                                variant='outlined'
                                color={'primary'}
                                size={'small'}
                                className={classes.okBtn}
                                onClick={handleOk}
                            >
                                YES
                            </Button>
                            <Button
                                type='button'
                                variant='outlined'
                                size={'small'}
                                onClick={handleClose}
                                className={classes.cancelBtn}
                            >
                                CANCEL
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>

            </Dialog>
        </div>
    );
}

ConfirmationModal.PropType = {
    open: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired
}

export default ConfirmationModal;
