import jwtDecode from "jwt-decode";

//Auth related functions
export function isLoggedIn() {
    const token = localStorage.getItem('token');
    return token ? true : false;
}

export function isAdministrator() {
    const token = localStorage.getItem('token');
    if (isLoggedIn()){
        const decodedInfo = jwtDecode(token);
        return decodedInfo.admin;
    }
    return false;
}

export function getPropByString(obj, path) {
    if (Object.keys(obj).length === 0 && obj.constructor === Object) return;

    if (!path) return;

    path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    path = path.replace(/^\./, ""); // strip a leading dot

    const propKeys = path.split(".");
    let result;
    for (let i = 0, n = propKeys.length; i < n; ++i) {
        let propKey = propKeys[i];
        if (propKey in obj) {
            result = obj[propKey];
        } else {
            return;
        }
    }
    return result;
}

export function adopteParams(initialObj, fieldValue, currentRow) {
    let key = Object.keys(initialObj).find(key => initialObj[key] === "#field#");
    let params = {...initialObj};
    params[key] = fieldValue;
    Object.keys(initialObj).forEach(key => {
        if (initialObj[key].includes("##")) {
            params[key] = getPropByString(currentRow, initialObj[key].replace("##", ""));
        }
    });
    return params;
}


export const getUserOptions = (users) => {
    if (!users) return [];
    return users.map((user) => {
        return {Name: user.Name, User: user.User};
    });
};

export const getRegionOptions = (regions) => {
    if (!regions) return [];
    return regions.map((region) => {
        return {
            RegionId: region.sk,
            Name: region.Name,
            ClientId: region.ClientId
        }
    });
};

export const getSiteOptions = (sites) => {
    if (!sites) return [];
    return sites.map((site) => {
        return {
            SiteId: site.sk,
            Name: site.Name,
            ClientId: site.Client?.ClientId
        }
    });
};

export const getSitesByClient = (sites, clientId) => {
    if (!sites) return [];
    return sites.filter(site => {
        return site.Client?.ClientId === clientId
    }).map((site) => {
        return {
            SiteId: site.sk,
            Name: site.Name,
            ClientId: site.Client?.ClientId
        }
    });
}

export const getSitesByClients = (sites, clients) => {
    if (!sites) return [];
    const clientIds = clients.map(client => client.ClientId);
    return sites.filter(site => {
        return clientIds.includes(site.Client?.ClientId);
    }).map((site) => {
        return {
            SiteId: site.sk,
            Name: site.Name,
            ClientId: site.Client?.ClientId
        }
    });
}

export const getRegionsByClient = (regions, clientId) => {
    if (!regions) return [];
    return regions.filter(region => region.Client?.ClientId === clientId)
        .map((region) => {
            return {
                RegionId: region.sk,
                Name: region.Name,
                ClientId: region.ClientId
            }
        });
}


export const getClientOptions = (clients) => {
    if (!clients) return [];
    return clients.map((client) => {
        return {
            Name: client.Name,
            ClientId: client.sk
        };
    });
};

export const getDevicesBySiteId = (devices, siteId) => {
    if (!devices) return [];
    return devices.filter(device => device.Site?.SiteId === siteId)
        .map((device) => {
            return {
                DeviceId: device.sk,
                User: device.User,
                ClientId: device.Client?.ClientId
            }
        });
}

export const isDeviceOffline = (device) => {
    const seconds = 300 * 1000; //300 seconds 
    const healthCheck = device['DeviceHealth'];
    if (healthCheck) {
        const lastReportedCheck = healthCheck.CreatedAt;
        const aliveDeviceCheck = Date.now() - seconds;
        return lastReportedCheck < aliveDeviceCheck;
    }
    return true;
}
