export const tokenLocalStorageName = "token";

export function handleResponse(response) {

  return response.text().then(text => {

    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem(tokenLocalStorageName);
        window.location.reload();
      }

      const error = (data && data.error && data.error.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
