import React, {Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers';
import {clientsActions, surfaceActions} from "../../_actions";
import BackLinkButton from "../../_components/BackLinkButton";
import SubmitButton from "../../_components/SubmitButton";
import {FieldTypes} from '../../Common/constants';
import Field from "../../_components/Field";


const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'center'
    }
}));

const AddClient = ({location}) => {
    const clientObj = useSelector(state => state.clients.item);
    const {clientId} = useParams();
    const isUpdateFlow = location.pathname.includes('update');
    const dispatch = useDispatch();
    const classes = useStyles();

    const validationSchema = yup.object().shape({
        Name: yup.string().required('Name is required'),
        Description: yup.string().required('Description is required')
    });

    const {register, handleSubmit, errors, reset, formState} = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onTouched",
    });

    const submitHandler = (data) => {
        if (isUpdateFlow) {
            data['sk'] = clientId;
            dispatch(clientsActions.save(data, true));
        } else {
            dispatch(clientsActions.save(data, false));
        }
    }

    //Setting loaded client object for update case
    useEffect(() => {
        if (clientObj) {
            reset(clientObj);
        }
    }, [clientObj])


    useEffect(() => {
        dispatch(surfaceActions.showHeaderSearchBar(false));
        if (isUpdateFlow) {
            dispatch(clientsActions.getSingle(clientId));
            dispatch(surfaceActions.setPageTitle("Update Client"));
        } else {
            dispatch(surfaceActions.setPageTitle("Add Client"));
        }
    }, [])

    return (

        <Fragment>
            <Box>
                <BackLinkButton path={'/admin/clients'}/>
                <Container maxWidth='sm'>
                    <form onSubmit={handleSubmit(submitHandler)} autoComplete='off'>
                        <Box className={classes.fieldContainer}>
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='Name'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <Field
                                type={FieldTypes.TEXT_AREA}
                                name='Description'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <SubmitButton
                                formState={formState}
                                label={isUpdateFlow ? 'UPDATE' : 'ADD'}
                            />
                        </Box>
                    </form>
                </Container>
            </Box>
        </Fragment>
    );
}


export default AddClient;
