import {authHeader} from "../_helpers/auth-header";
import {handleResponse} from "./helper.service";
import config from "../config";

function getByDeviceSerialNumber(deviceSerial) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/devices/${deviceSerial}/health`, requestOptions)
        .then(handleResponse);
}


export const healthsService = {
    getByDeviceSerialNumber,
}
