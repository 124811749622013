import React from "react";
import PropTypes from "prop-types";
import {LinearProgress, makeStyles, withStyles} from "@material-ui/core";
import {lightGreen} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        backgroundColor: lightGreen[100],
        width: "100px",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    },
    progressBar: {
        display: 'flex',
        height: '20px',
        backgroundColor: lightGreen[400],
        justifyContent: 'center'
    },
    progressValue: {
        position: 'absolute',
        top: '2px',
        left: '30px',
        marginTop: '0px',
        fontSize: '12px',
        opacity: '0.8'
    }
}));

function BatteryStatusView(props) {
    const {value} = props;
    const classes = useStyles();
    const style = {
        width: `${value}%`
    }
    return (
        <div className={classes.root}>
            <span className={classes.progressBar} style={style}></span>
            <span className={classes.progressValue}>{`${value} V`}</span>
        </div>
    );
}

BatteryStatusView.PropType = {
    value: PropTypes.number.isRequired
};

export default BatteryStatusView;
