import React from 'react';

import './loader.css'

function Loader(){
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export { Loader }