import React from "react";
import PropTypes from "prop-types";
import {makeStyles, Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {alertActions} from "../_actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 600,
        wordBreak: "break-word",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    }
}));

function AlertSnackbar(props) {
    const {message, type, ...other} = props;
    const [alertVisible, setAlertVisible] = React.useState(!!message);
    const dispatch = useDispatch();

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertVisible(false);
        dispatch(alertActions.clear());
    };

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Snackbar
                open={alertVisible}
                autoHideDuration={5000}
                onClose={handleAlertClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={handleAlertClose} severity={type} variant={'filled'}>
                    {message}
                </Alert>
            </Snackbar>

        </div>
    );
}

AlertSnackbar.PropType = {
    message: PropTypes.string.isRequired
};

export default AlertSnackbar;
