import {authHeader} from "../_helpers/auth-header";
import {handleResponse} from "./helper.service";
import config from "../config";


function getByDeviceAndModeId(deviceSerial, modeId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/devices/${deviceSerial}/modes/${modeId}`, requestOptions)
        .then(handleResponse);
}

export const modeService = {
    getByDeviceAndModeId
};
