import React, {useEffect} from "react";
import {Route, Router, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {history} from "./_helpers";
import {alertActions, loaderActions} from "./_actions";
import PrivateRoute from "./_components/PrivateRoute";
import LoginForm from "./Account/LoginForm";
import AlertSnackbar from "./Common/alertSnackbar";
import FlowAdmin from "./Admin/FlowAdmin";
import AdminRoute from "./_components/AdminRoute";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import FlowNonAdmin from "./NonAdmin/FlowNonAdmin";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function App() {
    const alert = useSelector((state) => state.alert);
    const loader = useSelector((state) => state.loader);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
            dispatch(loaderActions.clear())
        });
    }, []);

    history.location.pathname === '/' && history.push('/non-admin');
    return (
        <>
            {alert.message && <AlertSnackbar message={alert.message} type={alert.type}/>}

            <Backdrop className={classes.backdrop} open={loader.isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Router history={history}>
                <Switch>
                    <AdminRoute path='/admin' component={FlowAdmin}/>
                    <PrivateRoute path="/non-admin" component={FlowNonAdmin}/>
                    <Route path="/login" component={LoginForm}/>
                </Switch>
            </Router>
        </>
    );
}

export {App};
