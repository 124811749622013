import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import {Box, IconButton} from "@material-ui/core";
import Field from "../../_components/Field";
import {FieldTypes} from "../../Common/constants";
import SubmitButton from "../../_components/SubmitButton";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import {Close as CancelIcon} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {deviceActions} from "../../_actions";
import {useDispatch, useSelector} from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {CopyToClipboard} from "react-copy-to-clipboard";
import TextField from "@material-ui/core/TextField/TextField";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        textAlign: 'center'
    },
    dialogAction: {
        padding: '44px'
    },
    dialogHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        paddingLeft: '15px',
        paddingRight: '15px',
        borderBottom: '1px solid #eee'
    },
    headerTitle: {
        paddingTop: '9px'
    },
    tokenDiv: {
        marginBottom: '30px',
        wordWrap: 'break-word'
    },
    copyBtn: {
        padding: '10px 50px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textTransform: 'capitalize',
        marginBottom: '12px'
    },
    input: {
        '& .MuiInputBase-root': {
            background: '#FFFFFF'
        },
        marginBottom: '25px'

    },
}));


const DeviceTokenModal = ({open, closeHandler, serialNumber}) => {
    const classes = useStyles();
    const loginResponse = useSelector((state) => state.devices?.loginResponse);
    const selectedDeviceUsername = useSelector((state) => state.devices?.item?.User ? state.devices?.item?.User : '' );
    const [openAlert, setOpenAlert] = useState(false);
    const [openCopiedAlert, setOpenCopiedAlert] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => {
        closeHandler();
    };
    const validationRules = {
        password: yup.string().required('Password is required')
    }
    const validationSchema = yup.object().shape(validationRules);
    const {register, handleSubmit, errors, formState} = useForm({
        defaultValues: {
            password: null
        },
        mode: 'onChange',
        resolver: yupResolver(validationSchema),

    });

    const submitHandler = (data) => {
        dispatch(deviceActions.deviceLogin(selectedDeviceUsername, data.password));
    };

    useEffect(() => {
        setOpenAlert(loginResponse && !loginResponse.ok);
    }, [loginResponse])

    useEffect(() => {
        dispatch(deviceActions.getSingle(serialNumber));
    }, [serialNumber])

    return (
        <div>
            <Dialog
                open={open}
            >
                <div className={classes.dialogHeader}>
                    <Typography variant={'h6'} className={classes.headerTitle}>Device Token</Typography>
                    <IconButton aria-label="cancel" onClick={handleClose}>
                        <CancelIcon/>
                    </IconButton>
                </div>
                <DialogContent>
                    <Container maxWidth='sm'>
                        {
                            loginResponse?.ok &&
                            <Box className={classes.fieldContainer}>
                                {
                                    openCopiedAlert &&
                                    <Alert
                                        variant={'filled'}
                                        severity={'success'}
                                        onClose={() => setOpenCopiedAlert(false)}
                                        style={{marginBottom: '25px'}}
                                    >
                                        Token copied to clipboard
                                    </Alert>

                                }
                                <Typography variant={'body2'} className={classes.tokenDiv}>
                                    {loginResponse.token}
                                </Typography>
                                <CopyToClipboard text={loginResponse?.token}
                                                 onCopy={() => setOpenCopiedAlert(true)}>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='secondary'
                                        size='large'
                                        className={classes.copyBtn}
                                    >
                                        Copy to Clipboard
                                    </Button>
                                </CopyToClipboard>

                            </Box>
                        }
                        {
                            !loginResponse?.ok &&
                            <form autoComplete='off' onSubmit={handleSubmit(submitHandler)}>
                                <Box className={classes.fieldContainer}>
                                    {
                                        openAlert &&
                                        <Alert
                                            variant={'filled'}
                                            severity={'error'}
                                            onClose={() => setOpenAlert(false)}
                                            style={{marginBottom: '25px'}}
                                        >
                                            {loginResponse?.error}
                                        </Alert>

                                    }
                                    <TextField
                                        className={classes.input}
                                        variant='outlined'
                                        fullWidth
                                        inputProps={{
                                            autoComplete: 'new-password',
                                            form: {
                                                autoComplete: 'off',
                                            },
                                        }}
                                        disabled={true}
                                        value={selectedDeviceUsername}
                                    />
                                    <Field
                                        type={FieldTypes.PASSWORD_FIELD}
                                        name='password'
                                        label='Password'
                                        register={register}
                                        errors={errors}
                                    />
                                </Box>
                                <DialogActions className={classes.dialogAction}>
                                    <SubmitButton
                                        label={'Generate Device Token'}
                                        formState={formState}
                                    />
                                </DialogActions>
                            </form>
                        }

                    </Container>
                </DialogContent>

            </Dialog>
        </div>
    );
}

DeviceTokenModal.PropType = {
    open: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired,
    serialNumber: PropTypes.string.isRequired
}

export default DeviceTokenModal;
