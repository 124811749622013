import {authHeader} from "../_helpers/auth-header";
import {handleResponse} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/alarms`, requestOptions).then(
        handleResponse
    );
}

function getByDeviceId(deviceSerial) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/devices/${deviceSerial}/alarms`, requestOptions)
        .then(handleResponse);
}

function getByClientId(clientId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/clients/${clientId}/alarms`, requestOptions)
        .then(handleResponse);
}

function getBySiteId(siteId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/sites/${siteId}/alarms`, requestOptions)
        .then(handleResponse);
}

function getLocationSpecific(locationId) {
    // TODO
    // return getDeviceSpecific(1);
}

export const alarmsService = {
    getAll,
    getByDeviceSerialNumber: getByDeviceId,
    getLocationSpecific,
    getByClientId,
    getBySiteId
};
