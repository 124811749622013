import {alarmsConstants} from "../_constants";
import {alarmsService} from "../_services";
import {alertActions, loaderActions} from "./";

export const alarmsActions = {
    getAll,
    getByDevice,
    getByClient,
    getBySite
};

function getAll() {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        alarmsService.getAll().then(
            (response) => {
                const alarms = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(alarms))
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}



function getByDevice(serialNumber) {
  return dispatch => {
      dispatch(loaderActions.isLoading());

    alarmsService.getByDeviceSerialNumber(serialNumber).then(
        (response) => {
            const alarms = response.data ? response.data.Items : [];
            dispatch(getAllSuccess(alarms))
            dispatch(loaderActions.clear());
        },
      error => {
          dispatchError(error, dispatch);
      }
    );
  };
}

function getByClient(clientId) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        alarmsService.getByClientId(clientId).then(
            (response) => {
                const alarms = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(alarms))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function getBySite(siteId) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        alarmsService.getBySiteId(siteId).then(
            (response) => {
                const alarms = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(alarms))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}
/*
function getByLocation(locationId) {
  return dispatch => {
    dispatch(request());

    alarmsService.getLocationSpecific(locationId).then(
      response => dispatch(success(response.data.Items)),
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}
*/

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function getAllSuccess(alarms) {
    return {type: alarmsConstants.GET_ALL_SUCCESS, alarms};
}

function saveSuccess(alarm) {
    return {type: alarmsConstants.SAVE_SUCCESS, alarm};
}

function getSingleSuccess(alarm) {
    return {type: alarmsConstants.GET_SINGLE_SUCCESS, alarm};
}

function failure(error) {
    return {type: alarmsConstants.FAILURE, error};
}
