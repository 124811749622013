import {deviceConstants} from "../_constants/";

export function devices(state = {}, action) {
    switch (action.type) {
        case deviceConstants.REQUEST:
            return {
                loading: true,
            };
        case deviceConstants.GET_ALL_SUCCESS:
            return {
                items: action.devices,
                hideDeviceCard: !!action.hideFlag
            };
        case deviceConstants.SAVE_SUCCESS:
            return {
                item: action.device,
            };
        case deviceConstants.DEVICE_LOGIN_REQUEST:
            return {
                ...state,
                loginResponse: action.response,
            };
        case deviceConstants.DEVICE_LOGIN_CLEAR_TOKEN:
            return {
                ...state,
                loginResponse: undefined,
            };
        case deviceConstants.GET_SINGLE_SUCCESS:
            return {
                ...state,
                item: action.device.data,
            };
        case deviceConstants.FAILURE:
            return {
                error: action.error,
            };
        case deviceConstants.DEVICES_CARD_TOGGLE:
            return {
                ...state,
                hideDeviceCard: !!action.hideFlag
            };
        default:
            return state;
    }
}

