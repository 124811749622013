import {siteConstants} from "../_constants/";

export function sites(state = {}, action) {
    switch (action.type) {
        case siteConstants.REQUEST:
            return {
                loading: true,
            };
        case siteConstants.GET_ALL_SUCCESS:
            return {
                items: action.sites,
            };
        case siteConstants.SAVE_SUCCESS:
            return {
                item: action.site,
            };
        case siteConstants.GET_SINGLE_SUCCESS:
            return {
                item: action.site.data,
            };
        case siteConstants.FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}

