import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { alert } from "./alert.reducer";
import { devices } from "./devices.reducer";
import { alarms } from "./alarms.reducer";
import { surface } from "./surface.reducer";
import { clients } from "./clients.reducer";
import { sites } from "./sites.reducer";
import { users } from "./users.reducer";
import { regions } from "./regions.reducer";
import { loader } from "./loader.reducer";
import { modes } from "./modes.reducer";
import { healths } from "./healths.reducer";
import { errors } from "./errors.reducer";


const rootReducer = combineReducers({
  authentication,
  alert,
  alarms,
  clients,
  devices,
  surface,
  sites,
  users,
  regions,
  loader,
  modes,
  healths,
  errors
});

export default rootReducer;
