import React from 'react';
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LabeledText from "../../Common/labeledText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BatteryStatusView from "./BatteryStatusView";
import {Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
    },
    gridCard: {
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0)',
        borderRadius: '0px',
        maxHeight: '400px',
        overflow: 'auto'
    }
}));

const FactoryCard = ({device}) => {
    const {DeviceInfo, ServiceInfo} = device;
    const {latestAlarm} = device;
    const classes = useStyles();

    return (
        <Card className={classes.gridCard}>
            <CardHeader
                className={classes.cardHeader}
                title="Factory"
                titleTypographyProps={{variant: 'button'}}
            />
            <CardContent>
                {DeviceInfo &&
                <Grid container direction="row" spacing={4} style={{paddingBottom: '15px'}}>
                    <Grid item lg={6}>
                        <LabeledText label="Sample Count" text={ServiceInfo?.SampleCount}/>
                    </Grid>
                    <Grid item lg={6}>
                        <LabeledText label="Alarm Count" text={latestAlarm?.AlarmCount}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledText label="Battery"/>
                        <BatteryStatusView
                            value={DeviceInfo ? DeviceInfo.BatteryVoltage ? DeviceInfo.BatteryVoltage : '0' : '0'}/>
                    </Grid>
                </Grid>
                }
                {!DeviceInfo &&
                <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    style={{textAlign: "center", fontWeight: 'bold'}}
                >
                    No Factory Info Found
                </Typography>
                }
            </CardContent>
        </Card>
    );
}

export default FactoryCard;
