import {healthConstants} from "../_constants";
import {healthsService} from "../_services";
import {alertActions, loaderActions} from "./";

export const healthActions = {
    getByDevice,
};

function getByDevice(serialNumber) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        healthsService.getByDeviceSerialNumber(serialNumber).then(
            (response) => {
                const healthChecks = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(healthChecks))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function getAllSuccess(healthChecks) {
    return {type: healthConstants.GET_ALL_SUCCESS, healthChecks};
}

function failure(error) {
    return {type: healthConstants.FAILURE, error};
}
