import {healthConstants} from "../_constants";

export function healths(state = {}, action) {
    switch (action.type) {
        case healthConstants.REQUEST:
            return {
                loading: true,
            };
        case healthConstants.GET_ALL_SUCCESS:
            return {
                items: action.healthChecks,
            };
        case healthConstants.FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
