import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {alertActions} from "../../_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Map from "../../_components/Map";
import DeviceGeneralMap from "./DeviceGeneralMap";
import DataTable from "../../_components/DataTable";
import {alarmsActions} from "../../_actions/alarms.actions";
import DeviceInfoCard from "./DeviceInfoCard";
import SensorOutputCard from "./SensorOutputCard";
import FactoryCard from "./FactoryCard";
import DateTimeView from "../../Common/dateTimeView";
import PropTypes from "prop-types";
import LocationIcon from "../../Common/LocationIcon";
import ImagePopoverIcon from "../../Common/ImagePopoverIcon";
import VideoPopoverIcon from "../../Common/VideoPopoverIcon";
import ResultCard from "./ResultCard";
import HealthChecksCard from "./DeviceHealthCard";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
    },
    gridCard: {
        background: theme.palette.background.paper,
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.1), 0px 0px 5px 0px rgba(0,0,0,0)',
        borderRadius: '0px',
        maxHeight: '400px',
        overflow: 'auto'
    },
    mapCard: {
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
        // padding: '5px 5px',
        height: '235px',
    },
    rowCssClass: {
        background: '#FDFDFD'
    }
}));

const TabPanelGeneral = ({device}) => {
    const alarms = useSelector((state) => {
        return {
            items: state.alarms.items?.sort((a, b) => b['DateTime'] - a['DateTime']).slice(0, 10)
        }
    });

    // const device = useSelector((state) => state.devices.item);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        dispatch(alarmsActions.getByDevice(device.SerialNumber));
        // dispatch(deviceActions.getSingle(serialNumber));
        dispatch(alertActions.clear());
    }

    const columns = [
        {
            header: "Serial Number",
            field: "SerialNumber",
        },
        {
            header: "Operator",
            field: "OperatorID",
        },
        {
            header: "Alarm Count",
            field: "AlarmCount",
        },
        {
            header: "Type",
            field: "Type",
        },
        {
            header: "Identification",
            field: "Identification",
        },
        {
            header: "DATE AND TIME",
            field: "DateTime",
            template: DateTimeView,
            parameters: {
                dateTime: "#field#"
            }
        },
        {
            header: "Location",
            field: "Coordinates",
            template: LocationIcon,
            parameters: {
                coordinates: '#field#',
                serialNumber: '##SerialNumber'
            }
        },
        {
            header: "Photo",
            field: "ItemPhoto",
            template: ImagePopoverIcon,
            parameters: {
                url: '#field#',
                serialNumber: '##SerialNumber'
            }
        },
        {
            header: "Video",
            field: "ItemVideo",
            template: VideoPopoverIcon,
            parameters: {
                url: '#field#',
                serialNumber: '##SerialNumber'
            }
        }
    ];

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item sm={7} xs={12}>
                    {
                        device && <DeviceInfoCard device={device}/>
                    }
                </Grid>

                <Grid item sm={5} xs={12}>
                    <div className={classes.mapCard}>
                        {
                            device &&
                            <Map>
                                <DeviceGeneralMap coordinates={device?.DeviceInfo?.Coordinates}/>
                            </Map>
                        }
                    </div>
                </Grid>

                <Grid item xs={12} lg={4}>
                    {
                        device && <SensorOutputCard device={device}/>
                    }
                </Grid>

                <Grid item xs={12} lg={3} style={{height: '100%'}}>
                    {
                        device && <FactoryCard device={device}/>
                    }
                </Grid>
                <Grid item xs={12} lg={3} style={{height: '100%'}}>
                    {
                        device && <ResultCard device={device}/>
                    }
                </Grid>
                <Grid item xs={12} lg={2} style={{height: '100%'}}>
                    {
                        device && <HealthChecksCard device={device}/>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.gridCard}>
                        <CardHeader
                            className={classes.cardHeader}
                            title="History (Last 10 Alarms)"
                            titleTypographyProps={{variant: 'button'}}
                        />
                        <CardContent>
                            {
                                alarms ?
                                    <DataTable
                                        dataCollection={alarms}
                                        columns={columns}
                                        paginationNotRequired={true}
                                        rowCssClass={classes.rowCssClass}
                                    />
                                    : null
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

TabPanelGeneral.PropType = {
    device: PropTypes.object.isRequired
};
export default TabPanelGeneral;
