import {errorConstants} from "../_constants";
import {errorsService} from "../_services";
import {alertActions, loaderActions} from "./";

export const errorActions = {
    getByDevice,
};

function getByDevice(serialNumber) {
    return dispatch => {
        dispatch(loaderActions.isLoading());

        errorsService.getByDeviceSerialNumber(serialNumber).then(
            (response) => {
                const errors = response.data ? response.data.Items : [];
                dispatch(getAllSuccess(errors))
                dispatch(loaderActions.clear());
            },
            error => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function getAllSuccess(errors) {
    return {type: errorConstants.GET_ALL_SUCCESS, errors};
}

function failure(error) {
    return {type: errorConstants.FAILURE, error};
}
