export const clientsConstants = {

    GET_ALL_SUCCESS: "CLIENTS_GET_ALL_SUCCESS",

    GET_SINGLE_SUCCESS: "CLIENTS_GET_SINGLE_SUCCESS",

    REQUEST: "CLIENTS_REQUEST",
    SAVE_SUCCESS: "CLIENTS_SAVE_SUCCESS",
    FAILURE: "CLIENTS_FAILURE",
};
