import React, {Fragment, useEffect, useState} from "react";
import {makeStyles,} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Tab from "@material-ui/core/Tab";
import {deviceActions, surfaceActions} from "../../_actions";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import TabPanelAlarms from "./TabPanelAlarms";
import TabPanelGeneral from "./TabPanelGeneral";
import BackLinkButton from "../../_components/BackLinkButton";
import TabPanelErrors from "./TabPanelErrors";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '-12px'
    },

}));


function DeviceTabContainer({match}) {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState('general');
    const device = useSelector((state) => state.devices.item);
    const dispatch = useDispatch();

    const handleChange = (event, selectedTab) => {
        setSelectedTab(selectedTab);
    }

    const serialNumber = match.params?.serialNumber;

    const showErrorTab = () => {
        if (device && device.Error && device.Error.ErrorCode !== 'E00') {
            return true;
        }
        return false;
    }

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle(serialNumber));
        dispatch(surfaceActions.setLeftDrawerRequired(false));
        dispatch(deviceActions.getSingle(serialNumber));
    }, []);

    return (
        <Fragment>
            <BackLinkButton path={'/non-admin/dashboard'}/>
            <Tabs
                className={classes.root}
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                centered
                onChange={handleChange}

            >
                <Tab label="General" value='general'/>
                <Tab label="Alarms" value='alarms'/>
                {
                    showErrorTab() && <Tab label="Maintenance" value='errors'/>
                }

            </Tabs>
            <Box pt={1}>
                {
                    selectedTab === 'general' && device &&
                    <TabPanelGeneral serialNumber={serialNumber} device={device}/>
                }
                {
                    selectedTab === 'alarms' && <TabPanelAlarms serialNumber={serialNumber}/>
                }
                {
                    selectedTab === 'errors' && <TabPanelErrors serialNumber={serialNumber}/>
                }
            </Box>
        </Fragment>
    );
}

export default DeviceTabContainer;
