import React, {useEffect, useState} from "react";
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alarmsActions, alertActions} from "../../_actions";
import DateTimeView from "../../Common/dateTimeView";
import LocationIcon from "../../Common/LocationIcon";
import ImagePopoverIcon from "../../Common/ImagePopoverIcon";
import PropTypes from "prop-types";
import ActionsMenu from "../../_components/ActionMenu";
import VideoPopoverIcon from "../../Common/VideoPopoverIcon";
import AlarmPopover from "../../Common/AlarmPopover";


const TabPanelAlarms = ({serialNumber}) => {
    const alarms = useSelector((state) => state.alarms);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        dispatch(alarmsActions.getByDevice(serialNumber));
        dispatch(alertActions.clear());
    }

    const rowClickHandler = (event, alarmId) => {
        event.stopPropagation();
        setSelectedAlarm(alarms?.items.find(alarm => alarm.sk === alarmId));
        setAnchorEl(event.currentTarget)
    }

    const popoverCloseHandler = () => {
        setAnchorEl(null);
    }

    const columns = [
        {
            header: "Serial Number",
            field: "SerialNumber",
        },
        {
            header: "Operator",
            field: "OperatorID",
        },
        {
            header: "Alarm Count",
            field: "AlarmCount",
        },
        {
            header: "Type",
            field: "Type",
        },
        {
            header: "Identification",
            field: "Identification",
        },
        {
            header: "DATE AND TIME",
            field: "DateTime",
            template: DateTimeView,
            parameters: {
                dateTime: "#field#"
            }
        },
        {
            header: "Location",
            field: "Coordinates",
            template: LocationIcon,
            parameters: {
                coordinates: '#field#',
                serialNumber: '##SerialNumber'
            }
        },
        {
            header: "Photo",
            field: "ItemPhoto",
            template: ImagePopoverIcon,
            parameters: {
                url: '#field#',
                serialNumber: '##SerialNumber'
            }
        },
        {
            header: "Video",
            field: "ItemVideo",
            template: VideoPopoverIcon,
            parameters: {
                url: '#field#',
                serialNumber: '##SerialNumber'
            }
        }
    ];

    return (
        <div>
            {
                alarms ?
                    <DataTable
                        dataCollection={alarms}
                        columns={columns}
                        paginationNotRequired={true}
                        size={'medium'}
                        rowClickListener={rowClickHandler}
                        rowClickListenerIdentifier='sk'
                    />
                    : null
            }
            <AlarmPopover anchorEl={anchorEl} alarm={selectedAlarm} handleClose={popoverCloseHandler}/>
        </div>
    );
}

TabPanelAlarms.PropType = {
    serialNumber: PropTypes.string.isRequired
};

export default TabPanelAlarms;
