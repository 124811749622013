import {deviceConstants, userConstants} from "../_constants";
import {devicesService, usersService} from "../_services";
import {history} from "../_helpers";
import {alertActions, loaderActions} from './'

function getList(searchVal) {

    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.getAll().then(
            (response) => {
                let users = response.data ? response.data.Items : [];
                if(searchVal) {
                    dispatch(getAllSuccess(filterResult(users, searchVal)));
                } else {
                    dispatch(getAllSuccess(users));
                }
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function filterResult (users, searchVal) {
    return users.filter(item => {
        return item.Name?.toLowerCase().includes(searchVal.toLowerCase())
            || item.Description?.toLowerCase().includes(searchVal.toLowerCase())
            || item.Email?.toLowerCase().includes(searchVal.toLowerCase())
            || item.User?.toLowerCase().includes(searchVal.toLowerCase())
            || (item.Roles && item.Roles.filter(role => role.toLowerCase().includes(searchVal.toLowerCase())).length > 0)
    });
}


function getSingle(id) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.getSingle(id).then(
            (response) => {
                dispatch(getSingleSuccess(response));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };

}

function save(data, isUpdate) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.save(data, isUpdate).then(
            (response) => {
                dispatch(saveSuccess(response));
                history.push('/admin/users');
                const message = isUpdate ? 'User updated successfully' : 'User saved successfully';
                dispatch(alertActions.success(message));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function deleteUser(userId) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.deleteUser(userId).then(
            (response) => {
                dispatch(alertActions.success('User Deleted Successfully'));
                dispatch(usersActions.getList());
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatchError(error, dispatch);
            }
        );
    };
}

function userLogin(username, password) {
    return (dispatch) => {
        dispatch(loaderActions.isLoading());

        usersService.userLogin(username, password).then(
            (response) => {
                dispatch(userLoginResponse(response));
                dispatch(loaderActions.clear());
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };
}

function dispatchError(error, dispatch) {
    dispatch(failure(error.toString()));
    dispatch(alertActions.error(error.toString()));
    dispatch(loaderActions.clear());
}

function userLoginResponse(response) {

    return {type: userConstants.USER_LOGIN_REQUEST, response};
}

function getAllSuccess(users) {
    return {type: userConstants.GET_ALL_SUCCESS, users};
}

function saveSuccess(user) {
    return {type: userConstants.SAVE_SUCCESS, user};
}

function getSingleSuccess(user) {
    return {type: userConstants.GET_SINGLE_SUCCESS, user};
}

function failure(error) {
    return {type: userConstants.FAILURE, error};
}

function clearUserToken() {

    return {type: userConstants.USER_LOGIN_CLEAR_TOKEN};
}

export const usersActions = {
    getList,
    save,
    getSingle,
    deleteUser,
    userLogin,
    clearUserToken
};
