import {regionsConstants } from "../_constants/";

export function regions(state = {}, action) {
  switch (action.type) {
    case regionsConstants.REQUEST:
      return {
        loading: true,
      };
    case regionsConstants.GET_ALL_SUCCESS:
      return {
        items: action.regions,
      };
    case regionsConstants.SAVE_SUCCESS:
      return {
        item: action.regions,
      };
    case regionsConstants.GET_SINGLE_SUCCESS:
      return {
        item: action.region.data,
      };
    case regionsConstants.FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

