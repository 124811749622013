import React from 'react';
import {useHistory} from "react-router-dom";
import {ArrowBack as BackIcon} from "@material-ui/icons";
import Button from "@material-ui/core/Button";


const BackLinkButton = ({path}) => {
    const history = useHistory();

    return (
        <Button
            style={{marginBottom: '10px'}}
            color="primary"
            startIcon={<BackIcon/>}
            onClick={() => history.push(path)}
        >
            Back
        </Button>
    );
}

export default BackLinkButton;