import React, {Fragment} from "react";
import {useLoadScript} from "@react-google-maps/api";
import config from '../config'
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}));

const Map = (props) => {
    const classes = useStyles();

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: config.mapsKey
    })
    return (
        <Fragment>
            {
                loadError && <h4>Something went wrong while loading Map</h4>
            }
            {
                isLoaded ?
                    props.children
                    :
                    <Box className={classes.loader}>
                        <Typography variant={"body2"} style={{paddingTop: '2px'}}>Loading...</Typography>
                    </Box>
            }
        </Fragment>
    );
};

export default Map;
