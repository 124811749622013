import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Grid, makeStyles, Typography,} from "@material-ui/core";
import InfoCard from "./InfoCard";
import DataTable from "../../_components/DataTable";
import Box from "@material-ui/core/Box";
import Map from "../../_components/Map";
import DashboardMap from "./DashboardMap";
import {surfaceActions} from "../../_actions";
import DeviceStatusView from "../../Common/DeviceStatusView";
import {isDeviceOffline} from "../../Common/helpers";
import SerialNumberView from "../../Common/SerialNumberView";


const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        width: '130px'
    },
    alarmTitle: {
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold
    },
    gridCard: {
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
        padding: '12px 12px',
        maxHeight: '400px',
        overflow: 'auto'
    },
    mapCard: {
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
        padding: '5px 5px',
        height: '310px',
    },
}));

function Dashboard({history}) {
    const classes = useStyles();
    const alarms = useSelector((state) => state.alarms);
    const devices = useSelector((state) => state.devices);
    const [selectedDeviceSerial, setSelectedDeviceSerial] = useState(null);
    const dispatch = useDispatch();

    const viewDeviceDetail = (closeHandler, serialNumber) => {
        closeHandler();
        history.push(`/non-admin/device/detail/${serialNumber}`);
    }
    const columns = [
        {
            header: "Device",
            template: SerialNumberView,
            sendCompleteObjectAsParameter: true
        },
        {
            header: "Status",
            template: DeviceStatusView,
            sendCompleteObjectAsParameter: true
        },
        {
            header: "Client",
            field: "Client.Name",
        },
        {
            header: "Assigned to Site",
            field: "Site.Name",
        },
        {
            header: "Description",
            field: "Description",
        },
        {
            header: "Actions",
            field: "SerialNumber",
            disableSorting: true,
            actions: [
                {clickListener: viewDeviceDetail, label: 'Device Detail'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    function getTotalAlarmsNotificationCount() {
        return (alarms && alarms.items) ? alarms.items.length : 0;
    }

    function getDevicesCount() {
        return (devices && devices.items) ? devices.items.length : 0;
    }

    function getNotificationCountByType(type) {
        return (alarms && alarms.items) ?
            alarms.items.filter(alarm => alarm.Type?.toUpperCase() === type?.toUpperCase()).length
            : 0;
    }


    function getOfflineDeviceCount() {
        return (devices && devices.items) ?
            devices.items.filter(isDeviceOffline).length : 0;
    }

    const rowClickHandler = (event, serialNumber) => {
        event.stopPropagation();
        setSelectedDeviceSerial(serialNumber);
    }

    const rowDoubleClickHandler = (event, serialNumber) => {
        event.stopPropagation();
        history.push(`/non-admin/device/detail/${serialNumber}`);
    }

    useEffect(() => {
        dispatch(surfaceActions.setPageTitle("Dashboard"));
        dispatch(surfaceActions.setLeftDrawerRequired(true));
    }, []);

    return (
        <Fragment>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="space-between"
            >
                <Grid item sm>
                    <InfoCard title="Current Alerts" text={getTotalAlarmsNotificationCount()}/>
                </Grid>
                {
                    devices && !devices.hideDeviceCard && <Grid item xs>
                        <InfoCard title="Total Devices" text={getDevicesCount()}/>
                    </Grid>
                }
                {
                    devices && !devices.hideDeviceCard && <Grid item xs>
                        <InfoCard title="Offline" text={getOfflineDeviceCount()}/>
                    </Grid>
                }
                <Grid item sm>
                    <InfoCard title="Explosive Alerts" text={getNotificationCountByType('Explosives')}/>
                </Grid>
                <Grid item sm>
                    <InfoCard title="Narcotics Alerts" text={getNotificationCountByType('Narcotics')}/>
                </Grid>
            </Grid>

            <Box mt={2} className={classes.gridCard}>
                <Typography variant="body2" className={classes.alarmTitle}>Devices</Typography>
                {
                    alarms ?
                        <DataTable
                            dataCollection={devices}
                            columns={columns}
                            paginationNotRequired={true}
                            rowClickListener={rowClickHandler}
                            rowClickListenerIdentifier='SerialNumber'
                            rowDoubleClickListener={rowDoubleClickHandler}
                            rowDoubleClickListenerIdentifier='SerialNumber'
                        />
                        : null
                }
            </Box>

            <Box mt={2} className={classes.mapCard}>
                <Map>
                    <DashboardMap selectedSerialNumber={selectedDeviceSerial}/>
                </Map>
            </Box>
        </Fragment>
    );
}

export default Dashboard;
