const common = {
    mapsKey: process.env.REACT_APP_MAPS_KEY
};

const aws = {
    ...common,
    getAdminApiBaseUrl: () => `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}-admin`,
    getDeviceApiBAseUrl: () => `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}-devices`,
    getUserApiBAseUrl: () => `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}-users`,
};

const local = {
    ...common,
    getAdminApiBaseUrl: () => `${process.env.REACT_APP_API_URL}4500/staging`,
    getDeviceApiBAseUrl: () => `${process.env.REACT_APP_API_URL}4600/staging`,
    getUserApiBAseUrl: () => `${process.env.REACT_APP_API_URL}4700/staging`
};

const config = process.env.REACT_APP_STAGE === 'local' ? local : aws;

export default config;
