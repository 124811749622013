import {authHeader} from "../_helpers";
import {handleResponse} from "./helper.service";
import config from "../config";

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/users`, requestOptions).then(
        handleResponse
    );
}

function getSingle(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`${config.getAdminApiBaseUrl()}/users/${id}`, requestOptions).then(
        handleResponse
    );
}

const save = (userJson, isUpdate) => {
    let url;

    if (isUpdate) {
        url = `${config.getAdminApiBaseUrl()}/users/${userJson.User}`;
        // converting into desired format
        //e.g {update: {key:value}}
        userJson = {
            update: {
                ...userJson
            }
        }
    } else {
        url = `${config.getAdminApiBaseUrl()}/users`;
    }

    const requestOptions = {
        method: isUpdate ? 'PUT' : 'POST',
        headers: authHeader(),
        body: JSON.stringify(userJson)
    }
    return fetch(url, requestOptions)
        .then(handleResponse)
}

function userLogin(username, password) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({user: username, password}),
    };

    return fetch(`${config.getUserApiBAseUrl()}/login`, requestOptions)
        .then((response) => {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                return data;
            });
        });
}

function deleteUser(userId) {
    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

    return fetch(`${config.getAdminApiBaseUrl()}/users/${userId}`, requestOptions).then(
        handleResponse
    );
}

export const usersService = {
    getAll,
    getSingle,
    save,
    deleteUser,
    userLogin
};
