import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, sitesActions, surfaceActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";
import ConfirmationModal from "../../Common/ConfirmationModal";


const SiteList = () => {
    const sites = useSelector((state) => state.sites);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [selectedSiteId, setSelectedSiteId] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        refresh();
    }, []);

    function refresh() {
        dispatch(surfaceActions.showHeaderSearchBar(true));
        dispatch(surfaceActions.setPageTitle("Site List"));
        dispatch(sitesActions.getList());
        dispatch(alertActions.clear());
        dispatch(surfaceActions.setSearchFunc(search));
    }

    const search = (value) => {
        dispatch(sitesActions.getList(value));
    }

    /**Functions for action menus **/

    const editSite = (closeHandler, siteId) => {
        closeHandler();
        history.push(`/admin/site/update/${siteId}`);
    }
    const deleteSite = (closeHandler, siteId) => {
        closeHandler();
        setConfirmationModalOpen(true);
        setSelectedSiteId(siteId);

    }

    const confirmationModalCloseHandler = (event, proceedAction) => {
        setConfirmationModalOpen(false);
        if (proceedAction) {
            dispatch(sitesActions.deleteSite(selectedSiteId));
            setSelectedSiteId(null);
        }
    }

    const columns = [
        {
            header: "Site",
            field: "Name",
        },
        {
            header: "Client",
            field: "Client.Name",
        },
        {
            header: "Region",
            field: "Region.Name",
        },
        {
            header: "Description",
            field: "Description",
        },
        {
            header: "Actions",
            field: "sk",
            disableSorting: true,
            actions: [
                {clickListener: editSite, label: 'Edit'},
                {clickListener: deleteSite, label: 'Delete'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                sites ?
                    <DataTable dataCollection={sites} columns={columns}></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/site/new'}/>
            <ConfirmationModal open={confirmationModalOpen} closeHandler={confirmationModalCloseHandler}/>
        </div>
    );
}

export default SiteList;
