import {alarmsConstants} from "../_constants/alarms.constants";

export function alarms(state = {}, action) {
    switch (action.type) {
        case alarmsConstants.REQUEST:
            return {
                loading: true,
            };
        case alarmsConstants.GET_ALL_SUCCESS:
            return {
                items: action.alarms,
            };
        case alarmsConstants.SAVE_SUCCESS:
            return {
                item: action.alarm,
            };
        case alarmsConstants.GET_SINGLE_SUCCESS:
            return {
                item: action.alarm.data,
            };
        case alarmsConstants.FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
