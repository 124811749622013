export const deviceConstants = {
    GET_ALL_SUCCESS: "DEVICES_GET_ALL_SUCCESS",

    GET_SINGLE_SUCCESS: "DEVICES_GET_SINGLE_SUCCESS",
    REQUEST: "DEVICES_REQUEST",
    SAVE_SUCCESS: "DEVICES_SAVE_SUCCESS",
    FAILURE: "DEVICES_FAILURE",
    DEVICES_CARD_TOGGLE: "DEVICES_CARD_TOGGLE",
    DEVICE_LOGIN_REQUEST: 'DEVICE_LOGIN_REQUEST',
    DEVICE_LOGIN_CLEAR_TOKEN: 'DEVICE_LOGIN_CLEAR_TOKEN'

};
