import React from "react";
import PropTypes from "prop-types";
import {IconButton, makeStyles, Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(theme => ({
    moreButton: {
        padding: "6px"
    }
}));

function ActionsMenu(props) {
    const {identifier, actions} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const classes = useStyles();

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick} className={classes.moreButton}>
                <MoreVertIcon/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    actions.map((action, index) => {
                        return <MenuItem key={index}
                                         onClick={() => action.clickListener(handleClose, identifier)}>
                            {action.label}
                        </MenuItem>
                    })
                }
            </Menu>
        </div>
    );
}

ActionsMenu.PropType = {
    identifier: PropTypes.string.isRequired
};

export default ActionsMenu;
