import {surfaceConstants} from "../_constants";

export function surface(state = {drawerOpen: true}, action) {
    switch (action.type) {
        case surfaceConstants.TOGGLEDRAWER:
            return {...state, drawerOpen: !state.drawerOpen};
        case surfaceConstants.SETTITLE:
            return {...state, title: action.title};
        case surfaceConstants.LEFT_DRAWER_REQUIRED:
            return {...state, leftDrawerRequired: action.flag};
        case surfaceConstants.SHOW_HEADER_SEARCH_BAR:
            return {...state, showHeaderSearchBar: action.flag};
        case surfaceConstants.SET_SEARCH_FUNC:
            return {...state, searchFunc: action.searchFunc};
        default:
            return state;
    }
}
