import React from "react";
import PropTypes from "prop-types";
import {IconButton, makeStyles} from "@material-ui/core";
import {Close as CancelIcon} from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LabeledText from "./labeledText";
import Image from "material-ui-image/lib/components/Image/Image";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles(theme => ({
    iconButton: {
        padding: "6px"
    },
    gridCard: {
        width: "500px",
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
    },
    label: {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightBold,
    },
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
        '& .MuiCardHeader-action': {
            marginTop: '0px',
            '& .MuiIconButton-root': {
                padding: '2px'
            }
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

function AlarmPopover({alarm, anchorEl, handleClose}) {
    const classes = useStyles();

    const open = Boolean(anchorEl);
    return (
        <div className={classes.root}>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleClose(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Card className={classes.gridCard}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={'Alarm Detail'}
                        titleTypographyProps={{variant: 'button'}}
                        action={
                            <IconButton aria-label="cancel" onClick={handleClose}>
                                <CancelIcon/>
                            </IconButton>
                        }
                    />
                    {
                        alarm && alarm.ItemPhoto &&
                        <CardMedia
                            className={classes.media}
                            image={alarm?.ItemPhoto}
                        />
                    }

                    <CardContent className={classes.cardContent}>
                        <Grid container direction="row" justify="space-between">
                            <Grid item xs={6}>
                                <LabeledText label="Serial Number" text={alarm?.SerialNumber}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledText label="DateTime" text={alarm?.DateTime}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledText label="Type" text={alarm?.Type}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledText label="Identification" text={alarm?.Identification}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledText label="Alarm Resolution" text={alarm?.AlarmResolution}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledText label="Coordinates" text={alarm?.Coordinates}/>
                            </Grid>
                            <Grid itemxs={6}>
                                <LabeledText label="Operator ID" text={alarm?.OperatorId}/>
                            </Grid>
                            <Grid item xs={6}>
                                <LabeledText label="Alarm Count" text={alarm?.AlarmCount}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Popover>
        </div>
    );
}

AlarmPopover.PropType = {
    url: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
};

export default AlarmPopover;
