export const userConstants = {
  GET_ALL_SUCCESS: "USERS_GET_ALL_SUCCESS",

  GET_SINGLE_SUCCESS: "USERS_GET_SINGLE_SUCCESS",

  REQUEST: "USERS_REQUEST",
  SAVE_SUCCESS: "USERS_SAVE_SUCCESS",
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_CLEAR_TOKEN: 'USER_LOGIN_CLEAR_TOKEN',
  FAILURE: "USERS_FAILURE",
};
