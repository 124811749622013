import React from "react";
import PropTypes from "prop-types";
import {IconButton, makeStyles} from "@material-ui/core";
import {Close as CancelIcon, LocationOn as LocationMarker} from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import Map from "../_components/Map";
import DeviceGeneralMap from "../NonAdmin/Device/DeviceGeneralMap";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(theme => ({
    root: {},
    locationButton: {
        padding: "6px"
    },
    gridCard: {
        width: "500px",
        boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
        height: '300px',
    },
    label: {
        fontSize: 10,
        fontWeight: theme.typography.fontWeightBold,
    },
    cardHeader: {
        padding: '5px 16px',
        borderBottom: '1px outset rgba(0, 0, 0, 0.12)',
        '& .MuiCardHeader-action': {
            marginTop: '0px',
            '& .MuiIconButton-root': {
                padding: '2px'
            }
        }
    },
    cardContent: {
        padding: '0px'
    }
}));

function LocationIcon(props) {
    const {coordinates, serialNumber} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleCLick = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <div className={classes.root}>
            <IconButton
                color="primary"
                className={classes.locationButton}
                onClick={handleCLick}
            >
                <LocationMarker/>
            </IconButton>
            <Popover
                onClick={(event) => event.stopPropagation()}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card className={classes.gridCard}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={`ID: ${serialNumber}`}
                        titleTypographyProps={{variant: 'button'}}
                        action={
                            <IconButton aria-label="cancel" onClick={handleClose}>
                                <CancelIcon/>
                            </IconButton>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        {
                            coordinates &&
                            <Map>
                                <DeviceGeneralMap coordinates={coordinates} mapContainerStyle={{
                                    height: "261px",
                                    width: "100%"
                                }}/>
                            </Map>
                        }
                    </CardContent>
                </Card>
            </Popover>
        </div>
    );
}

LocationIcon.PropType = {
    coordinates: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired
};

export default LocationIcon;
