import {surfaceConstants} from "../_constants";

export const surfaceActions = {
    toggleDrawer,
    setPageTitle,
    setLeftDrawerRequired,
    showHeaderSearchBar,
    setSearchFunc
};

function toggleDrawer() {
    return {type: surfaceConstants.TOGGLEDRAWER};
}

function setPageTitle(title) {
    return {type: surfaceConstants.SETTITLE, title};
}

function setLeftDrawerRequired(flag) {
    return {type: surfaceConstants.LEFT_DRAWER_REQUIRED, flag};
}

function showHeaderSearchBar(flag) {
    return {type: surfaceConstants.SHOW_HEADER_SEARCH_BAR, flag};
}

function setSearchFunc(searchFunc) {
    return {type: surfaceConstants.SET_SEARCH_FUNC, searchFunc};
}
