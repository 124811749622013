import {modesConstants} from "../_constants/modes.constants";

export function modes(state = {}, action) {
    switch (action.type) {
        case modesConstants.REQUEST:
            return {
                loading: true,
            };
        case modesConstants.GET_ALL_SUCCESS:
            return {
                items: action.modes,
            };
        case modesConstants.FAILURE:
            return {
                error: action.error,
            };
        default:
            return state;
    }
}
