import React from "react";
import {GoogleMap, Marker,} from "@react-google-maps/api";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const mapContainerStyle = {
    height: "300px",
    width: "100%"
}

const center = {
    lat: 33.748997,
    lng: -84.387985
}

const options = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true
}

const DashboardMap = ({selectedSerialNumber}) => {
    const devices = useSelector((state) => state.devices.items);

    const getPosition = (coordinates) => {
        if (coordinates) {
            const latLongArray = coordinates.split(',');
            if (latLongArray && latLongArray.length === 2) {
                const position = {lat: +latLongArray[0].trim(), lng: +latLongArray[1].trim()}
                return position
            }
            return null;
        }
    }

    const getMarker = (device) => {
        const coordinates = device?.DeviceInfo?.Coordinates;
        const position = getPosition(coordinates);
        if (position) {
            return <Marker key={device.SerialNumber} position={position}/>
        }
        return null
    }

    const getMapCenter = () => {
        let mapCenterPosition;
        for (const device of devices) {
            const deviceCoordinates = device?.DeviceInfo?.Coordinates;
            if (deviceCoordinates && device.SerialNumber === selectedSerialNumber) {
                const position = getPosition(deviceCoordinates);
                mapCenterPosition = position;
                break;
            }
        }
        return mapCenterPosition ? mapCenterPosition : center;
    }

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={9}
            center={devices ? getMapCenter() : center}
            options={options}
        >
            {
                devices && devices.map(device => getMarker(device))
            }
        </GoogleMap>
    );
};

DashboardMap.PropType = {
    selectedSerialNumber: PropTypes.string
};
export default DashboardMap;
