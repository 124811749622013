import React, {Fragment, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers';
import {clientsActions, regionsActions, sitesActions, surfaceActions, usersActions} from "../../_actions";
import BackLinkButton from "../../_components/BackLinkButton";
import Field from "../../_components/Field";
import {FieldTypes} from "../../Common/constants";
import SubmitButton from "../../_components/SubmitButton";
import {getClientOptions, getRegionOptions, getRegionsByClient} from "../../Common/helpers";


const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiInputBase-root': {
            background: '#FFFFFF'
        },
        marginBottom: '20px'
    },
    submitBtn: {
        padding: '10px 50px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    fieldContainer: {
        textAlign: 'center'
    }
}));


const AddSite = ({location}) => {

    const clients = useSelector(state => state.clients.items);
    const regions = useSelector(state => state.regions.items);
    const siteObj = useSelector(state => state.sites.item);
    const isUpdateFlow = location.pathname.includes('update');
    const {siteId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();

    const validationSchema = yup.object().shape({
        Name: yup.string().required('Site location is required'),
        Description: yup.string().required('Description is required'),
        Client: yup.mixed().required('Client is required')
    });

    const {register, handleSubmit, errors, control, formState, reset, watch} = useForm({
        defaultValues: {
            Name: '',
            Region: null,
            Description: '',
            Client: null
        },
        mode: 'onTouched',
        resolver: yupResolver(validationSchema),

    });

    const watchClient = watch('Client');

    const submitHandler = (data) => {
        data['ClientId'] = data.Client?.ClientId;
        delete data['Client'];
        data['RegionId'] = data.Region ? data.Region.RegionId : null;
        delete data['Region'];
        if (siteId) {
            data['SiteId'] = siteId;
            dispatch(sitesActions.save(data, true));
        } else {
            dispatch(sitesActions.save(data, false));
        }
    };


    //Setting up loaded site object for update case
    useEffect(() => {
        dispatch(surfaceActions.showHeaderSearchBar(false));
        if (siteObj) {
            reset(siteObj);
        }
    }, [siteObj])


    useEffect(() => {
        dispatch(clientsActions.getList())
        dispatch(usersActions.getList());
        dispatch(regionsActions.getList());
        if (siteId) {
            dispatch(sitesActions.getSingle(siteId));
            dispatch(surfaceActions.setPageTitle("Update Site"));
        } else {
            dispatch(surfaceActions.setPageTitle("Add Site"));
        }
    }, [])


    return (

        <Fragment>
            <Box>
                <BackLinkButton path={'/admin/sites'}/>
                <Container maxWidth='sm'>
                    <form onSubmit={handleSubmit(submitHandler)} autoComplete='off'>
                        <Box className={classes.fieldContainer}>
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Client'
                                control={control}
                                errors={errors}
                                dropdownOptions={getClientOptions(clients)}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option.ClientId === value.ClientId}
                                label='Select Client'
                            />
                            <Field
                                type={FieldTypes.SELECT_FIELD}
                                name='Region'
                                control={control}
                                errors={errors}
                                dropdownOptions={(watchClient) ? getRegionsByClient(regions, watchClient.ClientId) : getRegionOptions(regions)}
                                dropdownOptionLabel={option => option.Name}
                                dropdownOptionSelected={(option, value) => option && value && option.RegionId === value.RegionId}
                                label='Select Region'
                            />
                            <Field
                                type={FieldTypes.TEXT_FIELD}
                                name='Name'
                                label='Site Name'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <Field
                                type={FieldTypes.TEXT_AREA}
                                name='Description'
                                register={register}
                                errors={errors}
                                isUpdateFlow={isUpdateFlow}
                            />
                            <SubmitButton
                                formState={formState}
                                label={isUpdateFlow ? 'UPDATE' : 'ADD'}
                            />
                        </Box>
                    </form>
                </Container>
            </Box>

        </Fragment>
    );
};

export default AddSite;
