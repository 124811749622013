import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import {isDeviceOffline} from './helpers'
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    offlineStatus: {
        color: '#FFF',
        background: '#f50057',
        padding: '3px',
        borderRadius: '20px',
    },
    onlineStatus: {
        color: '#FFF',
        background: 'green',
        padding: '3px 5px',
        borderRadius: '20px',
    },
}));

function DeviceStatusView(device) {
    // const device = props;
    const classes = useStyles();

    return (
        <Fragment>
            {
                isDeviceOffline(device) ?
                    <Typography className={classes.offlineStatus} variant={"caption"}>OFFLINE</Typography>
                    : <Typography className={classes.onlineStatus} variant={"caption"}>ONLINE</Typography>
            }
        </Fragment>
    );
}

DeviceStatusView.PropType = {
    device: PropTypes.object.isRequired
};

export default DeviceStatusView;
