import React, {Fragment, useEffect, useState} from "react";
import {fade, makeStyles,} from "@material-ui/core/index";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import {alarmsActions, alertActions, clientsActions, deviceActions, sitesActions, surfaceActions} from "../../_actions";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {
    AddBox as ExpandIcon,
    IndeterminateCheckBox as ContractIcon,
    Room as SiteIcon,
    Search as SearchIcon
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import {getSitesByClient} from "../../Common/helpers";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import clsx from "clsx";
import InputBase from "@material-ui/core/InputBase";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {withRouter} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '280px',
        flexShrink: 0,
    },
    drawerPaper: {
        top: '64px',
        background: '#fafafa',
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        padding: '10px 0px'
    },
    list: {
        paddingTop: '0px',
        paddingBottom: '0px',
        background: '#fff'
    },
    listItem: {
        padding: '1px 0px 1px 2px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#F5F5F5',
        },
    },
    siteListItem: {
        padding: '1px 0px 1px 20px',
    },
    deviceListItem: {
        padding: '5px 0px 5px 78px',
    },
    activeListItem: {
        borderRight: '5px solid #0079b2'
    },
    search: {
        position: 'relative',
        borderRadius: '20px',
        backgroundColor: fade(theme.palette.common.white, 1),
        border: '1px solid rgba(0, 0, 0, 0.12)',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(3),
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a09696'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',

    }
}));

function AppDrawer() {
    const clients = useSelector((state) => state.clients.items);
    const sites = useSelector((state) => state.sites.items);
    const surface = useSelector((state) => state.surface);
    const [selectedClient, setSelectedClient] = useState('');
    const [activeItem, setActiveItem] = useState('');
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    const dispatch = useDispatch();

    function refresh() {
        dispatch(alarmsActions.getAll());
        dispatch(clientsActions.getList());
        dispatch(sitesActions.getList());
        dispatch(deviceActions.getAll());
        dispatch(alertActions.clear());
    }

    function toggleDrawer() {
        dispatch(surfaceActions.toggleDrawer());
    }

    const onClientItemClick = (event, clientId) => {
        event.stopPropagation();
        setActiveItem(clientId);
        // setSelectedSite('');
        setSelectedClient(selectedClient === clientId ? '' : clientId);
        if (isSmallScreen) {
            toggleDrawer();
        }
        dispatch(deviceActions.hideDashboardDeviceCard(false));
        dispatch(deviceActions.getByClient(clientId));
        dispatch(alarmsActions.getByClient(clientId));

    }

    const onSiteItemClick = (event, siteId) => {
        event.stopPropagation();
        dispatch(deviceActions.getBySite(siteId));
        setActiveItem(siteId);
        dispatch(alarmsActions.getBySite(siteId));
        if (isSmallScreen) {
            toggleDrawer();
        }
        // setSelectedSite(selectedSite === siteId ? '' : siteId);
        // dispatch(deviceActions.hideDashboardDeviceCard(false));

    }

    const renderLeftMenuSiteItems = (clientId) => {
        return (
            selectedClient === clientId &&
            <Collapse in={true}>
                <List disablePadding={true} component='div' className={classes.list}>
                    {getSitesByClient(sites, clientId).map((site) =>
                        <ListItem button
                                  onClick={(event) => onSiteItemClick(event, site.SiteId)}
                                  key={site.SiteId}
                                  className={clsx(classes.listItem, classes.siteListItem,
                                      {[classes.activeListItem]: site.SiteId === activeItem})}
                                  dense={true}>
                            <ListItemIcon>
                                <IconButton edge="end" color='secondary'>
                                    <SiteIcon/>
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText primary={site.Name}/>
                        </ListItem>
                    )}
                </List>
            </Collapse>
        )
    }

    const renderLeftClientMenuItems = () => {
        return (
            clients &&
            <List className={classes.list}>
                {
                    clients?.map(client => (
                        <Fragment key={client.sk}>
                            <ListItem onClick={(event) => onClientItemClick(event, client.sk)}
                                      dense={true}
                                      className={clsx(classes.listItem,
                                          {[classes.activeListItem]: client.sk === activeItem})}>
                                <ListItemIcon>
                                    <IconButton edge="end" color='primary'>
                                        {client.sk === selectedClient ? <ContractIcon/> : <ExpandIcon/>}
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText primary={client.Name}/>
                            </ListItem>
                            {renderLeftMenuSiteItems(client.sk)}
                        </Fragment>
                    ))
                }
            </List>
        )
    }

    useEffect(() => {
        refresh()
    }, []);

    const leftMenuContent = () => {
        return (<Fragment>
            <div className={classes.toolbar}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon/>
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{'aria-label': 'search'}}
                    />
                </div>
            </div>
            <Divider/>
            {renderLeftClientMenuItems()}
        </Fragment>)
    }


    return (
        <Fragment>
            {
                isSmallScreen ?
                    <Drawer
                        className={classes.drawer}
                        variant={'temporary'}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        anchor="left"
                        open={!surface.drawerOpen}
                        onClose={toggleDrawer}
                    >
                        {leftMenuContent()}
                    </Drawer>
                    :
                    <Drawer
                        className={classes.drawer}
                        style={{width: surface.drawerOpen ? '280px' : '0px'}}
                        variant={'persistent'}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        anchor="left"
                        open={surface.drawerOpen}
                    >
                        {leftMenuContent()}
                    </Drawer>
            }
        </Fragment>

    );
}

export default withRouter(AppDrawer);
