import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import DataTable from "../../_components/DataTable";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, clientsActions, surfaceActions} from "../../_actions";
import FloatingActionButton from "../../_components/FloatingActionButton";
import ConfirmationModal from "../../Common/ConfirmationModal";


const ClientList = () => {
    const clients = useSelector((state) => state.clients);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    /**Functions for action menus **/
    const editClient = (closeHandler, clientId) => {
        closeHandler();
        history.push(`/admin/client/update/${clientId}`);
    }

    const deleteClient = (closeHandler, clientId) => {
        closeHandler();
        setConfirmationModalOpen(true);
        setSelectedClientId(clientId);

    }

    const confirmationModalCloseHandler = (event, proceedAction) => {
        setConfirmationModalOpen(false);
        if (proceedAction) {
            dispatch(clientsActions.deleteClient(selectedClientId));
            setSelectedClientId(null);
        }
    }

    const search = (value) => {
        dispatch(clientsActions.getList(value));
    }

    function refresh() {
        dispatch(clientsActions.getList());
        dispatch(alertActions.clear());
        dispatch(surfaceActions.setPageTitle("Client List"));
        dispatch(surfaceActions.showHeaderSearchBar(true));
        dispatch(surfaceActions.setSearchFunc(search));
    }

    useEffect(() => {
        refresh();
    }, []);

    const columns = [
        {
            header: "Name",
            field: "Name",
        },
        {
            header: "Descriptions",
            field: "Description",
        },
        {
            header: "Actions",
            field: "sk",
            disableSorting: true,
            actions: [
                {clickListener: editClient, label: 'Edit'},
                {clickListener: deleteClient, label: 'Delete'}
            ],
            parameters: {
                clientId: "#field#",
            },
        },
    ];

    return (
        <div>
            {
                clients ?
                    <DataTable dataCollection={clients} columns={columns}></DataTable>
                    : null
            }

            <FloatingActionButton path={'/admin/client/new'}/>
            <ConfirmationModal open={confirmationModalOpen} closeHandler={confirmationModalCloseHandler}/>
        </div>
    );
}

export default ClientList;
